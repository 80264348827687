import React, {useEffect, useState} from "react";
import {SliderMarks} from "antd/es/slider";
import {InputNumber, List, Slider, Radio} from "antd";
import LabelComponent from "../label/label-component";
import {calculateNewValue} from "@testing-library/user-event/dist/utils";
import {RadioComponentProps} from "./radio-component.types";


const RadioComponent = <T,>({
                                                           items,
                                                           label,
                                                           unit,
                                                           value,
                                                           defaultValue = value,
                                                           disabled = false,
                                                           onChangeValue
                                                       }: RadioComponentProps<T>) => {
    const [inputValue, setInputValue] = useState<T>(value);


    useEffect(() => {
        setInputValue(value);
    }, [value]);


    const onChange = (newValue: T | null) => {
        setInputValue(() => newValue ?? defaultValue);
        if (onChangeValue && value !== newValue) {
            onChangeValue(newValue ?? defaultValue);
        }
    };

    return (

        <>
            <LabelComponent label={label} unit={unit} marginLeft={6} marginTop={0}/>
            <List.Item style={{display: "flex", justifyContent: "center"}}>
                <Radio.Group defaultValue="c" buttonStyle="solid" size="middle" value={inputValue} onChange={(event) => onChange(event.target.value)}>
                    {items.map((radioItem => {
                        return <Radio.Button value={radioItem.value}>{radioItem.name}</Radio.Button>
                    }))}
                </Radio.Group>

            </List.Item>

        </>
    );
};

export default RadioComponent;