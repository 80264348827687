import React, {useEffect, useState} from 'react';
import {
    ConfigProvider,
    theme,
    Button,
    Tabs,
    Drawer,
    Space,
    Card,
    Layout,
    Row,
    Col,
    Slider,
    InputNumber,
    Switch,
    Table,
    TableColumnsType,
    Badge,
    Dropdown,
    Collapse,
    List,
    RadioChangeEvent,
    Radio,
    Flex
} from 'antd';
import logo from './logo.svg';
import {DownOutlined} from '@ant-design/icons';
import {SliderMarks} from 'antd/es/slider';
import {SizeType} from 'antd/es/config-provider/SizeContext';
import ComponentsSection from "./components/ui/section/components-section";
import AgcSection from "./components/logic/01-agc-section/agc-section";
import DisplaySection from "./components/logic/02-display-section/display-section";
import {webSerialPort} from "./api/web-serial-port/w-serial-port";
import {FTM891} from "./pages/ftm-891/ftm-891";
// import './App.css';


interface DataType {
    key: React.Key;
    name: string;
    locked: boolean;
}

interface ExpandedDataType {
    key: React.Key;
    date: string;
    name: string;
    upgradeNum: string;
}

const items = [
    {key: '1', label: 'Action 1'},
    {key: '2', label: 'Action 2'},
];

const AppTable: React.FC = () => {
    const expandedRowRender = () => {
        const columns: TableColumnsType<ExpandedDataType> = [
            {title: 'Date', dataIndex: 'date', key: 'date'},
            {title: 'Name', dataIndex: 'name', key: 'name'},
            {
                title: 'Status',
                key: 'state',
                render: () => <Badge status="success" text="Finished"/>,
            },
            {title: 'Upgrade Status', dataIndex: 'upgradeNum', key: 'upgradeNum'},
            {
                title: 'Action',
                dataIndex: 'operation',
                key: 'operation',
                render: () => (
                    <Space size="middle">
                        <a>Pause</a>
                        <a>Stop</a>
                        <Dropdown menu={{items}}>
                            <a>
                                More <DownOutlined/>
                            </a>
                        </Dropdown>
                    </Space>
                ),
            },
        ];

        const data = [];
        for (let i = 0; i < 3; ++i) {
            data.push({
                key: i.toString(),
                date: '2014-12-24 23:12:00',
                name: 'This is production name',
                upgradeNum: 'Upgraded: 56',
            });
        }
        return <Table columns={columns} dataSource={data} pagination={false}/>;
    };

    const columns: TableColumnsType<DataType> = [
        {title: 'ITEM', dataIndex: 'name', key: 'name'},
        {title: 'LOCK', key: 'name', render: () => <Switch/>},
    ];

    const data: DataType[] = [];

    data.push({
        key: 'agc',
        name: 'Automatic Gain Controll',
        locked: false,
    });

    for (let i = 0; i < 3; ++i) {
        data.push({
            key: i.toString(),
            name: 'Screen',
            locked: false,
        });
    }

    return (
        <>
            <Table
                columns={columns}
                expandable={{expandedRowRender, defaultExpandedRowKeys: ['0']}}
                dataSource={data}
                size="middle"
            />
        </>
    );
};


const {Header, Footer, Sider, Content} = Layout;

const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    // color: '#000',
    // height: 64,
    paddingInline: 50,
    // lineHeight: '64px',
    // backgroundColor: '#333333',
};

const contentStyle: React.CSSProperties = {
    // textAlign: 'center',
    minHeight: '100%',
    // maxWidth: '100%'
    // lineHeight: '120px',
    // color: '#fff !important',

    // backgroundColor: '#080808',
    // backgroundColor: '#000',
};

const siderStyle: React.CSSProperties = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    // backgroundColor: '#3ba0e9',
};

const footerStyle: React.CSSProperties = {
    textAlign: 'center',
    // color: '#fff',
    // backgroundColor: '#7dbcea',
};

function AppMenu() {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button type="primary" onClick={showDrawer}>
                Library
            </Button>
            <Drawer title="Library" placement="left" onClose={onClose} open={open}>
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Drawer>
        </>
    );
}


// const AgcSection: React.FC<IntegerStepProps> = ({ name }) => (
//   <List
//     size="small"
//     // header={<div>Header</div>}
//     // footer={<div>Footer</div>}
//     bordered
//   // dataSource={data}
//   // renderItem={(item) => <List.Item>{item}</List.Item>}
//   >
//     {/* <List.Item actions={[<Switch />]}> */}
//     <IntegerStep name="01-01 | AGC FAST DELAY" />
//     <IntegerStep name="01-02 | AGC MID DELAY" />
//     <IntegerStep name="01-03 | AGC SLOW DELAY" />
//     {/* <IntegerStep name={name} /> */}
//     {/* <IntegerStep name={name} /> */}
//
//   </List>
// );

// const DisplaySection: React.FC<IntegerStepProps> = ({ name }) => (
//   <List
//     size="small"
//     // header={<div>Header</div>}
//     // footer={<div>Footer</div>}
//     bordered
//     // style={{backgroundColor: '#000'}}
//     // dataSource={data}
//   // renderItem={(item) => <List.Item>{item}</List.Item>}
//   >
//     {/* <List.Item actions={[<Switch />]}> */}
//     <IntegerStep name="02-01 | LCD CONTRAST" />
//     <IntegerStep name="02-02 | DIMMER BACKLIT" />
//     <IntegerStep name="02-03 | DIMMER LCD" />
//     <IntegerStep name="02-04 | DIMMER TX/BUSY" />
//     <ContentSelect name="02-05 | PEAK HOLD" />
//     <IntegerStep name="02-06 | ZIN LED" />
//     <ContentButton name="02-07 | POP-UP MENU" />
//     {/* <IntegerStep name={name} /> */}
//     {/* <IntegerStep name={name} /> */}
//
//   </List>
// );

interface IntegerStepProps {
    name: string;
}

const IntegerStep: React.FC<IntegerStepProps> = ({name}: IntegerStepProps) => {
    const [inputValue, setInputValue] = useState(300);
    const [disabled, setDisabled] = useState(false);

    const onChange = (newValue: number | null) => {
        setInputValue(newValue ?? 0);
    };

    const onChangeDisable = (checked: boolean) => {
        setDisabled(checked);
    };

    const marks: SliderMarks = {
        20: '20ms',
        4000: '4s',
    };

    return (


        <List.Item actions={[<InputNumber

            min={20}
            max={4000}
            step={20}
            defaultValue={300}
            style={{margin: '0px 0px 0px 0px', maxWidth: '60px'}}
            value={inputValue}
            onChange={onChange}
            disabled={disabled}
            name={name}
        />]}>
            <Slider
                min={20}
                max={4000}
                step={20}
                defaultValue={300}
                onChange={onChange}
                value={typeof inputValue === 'number' ? inputValue : 0}
                disabled={disabled}
                style={{width: "100%"}}
                // marks={marks}
                included={false}
            />

            <p style={{
                position: 'absolute',
                marginTop: -25,
                marginLeft: '-10px',
                fontSize: '9px',
                color: '#c0c0c0'
            }}>{name} <span>(milliseconds)</span></p>
        </List.Item>

    );
};

const ContentSelect: React.FC<IntegerStepProps> = ({name}: IntegerStepProps) => {
    const [inputValue, setInputValue] = useState(300);
    const [disabled, setDisabled] = useState(false);

    const [value, setValue] = useState(1);

    const onChangeRadio = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const onChange = (newValue: number | null) => {
        setInputValue(newValue ?? 0);
    };

    const onChangeDisable = (checked: boolean) => {
        setDisabled(checked);
    };

    const marks: SliderMarks = {
        20: '20ms',
        4000: '4s',
    };

    return (

        <div>
            <List.Item style={{width: '100%', justifyContent: 'center'}}>
                <Radio.Group onChange={onChangeRadio} value={value} style={{marginTop: '5px'}}>
                    <Radio value={1}>OFF</Radio>
                    <Radio value={2}>0.5</Radio>
                    <Radio value={3}>1.0</Radio>
                    <Radio value={4}>2.0</Radio>
                </Radio.Group>

            </List.Item>
            <p style={{position: 'absolute', marginTop: -40, marginLeft: 5, fontSize: '9px', color: '#757070'}}>{name}
                <span>(seconds)</span></p>

        </div>

    );
};

const ContentButton: React.FC<IntegerStepProps> = ({name}: IntegerStepProps) => {
    const [inputValue, setInputValue] = useState(300);
    const [disabled, setDisabled] = useState(false);
    const [size, setSize] = useState<SizeType>('large'); // default is 'middle'


    const [value, setValue] = useState(1);

    const onChangeRadio = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const onChange = (newValue: number | null) => {
        setInputValue(newValue ?? 0);
    };

    const onChangeDisable = (checked: boolean) => {
        setDisabled(checked);
    };

    const marks: SliderMarks = {
        20: '20ms',
        4000: '4s',
    };

    return (

        <div>
            <List.Item style={{width: '100%', justifyContent: 'center', marginLeft: 50}}>
                <Radio.Group value={size} onChange={(e) => setSize(e.target.value)} size='small'>
                    <Radio.Button value="large1" style={{minWidth: '25px'}}>5</Radio.Button>
                    <Radio.Button value="default2">6.25</Radio.Button>
                    <Radio.Button value="default3">10</Radio.Button>
                    <Radio.Button value="default4">12.5</Radio.Button>
                    <Radio.Button value="default5">15</Radio.Button>
                    <Radio.Button value="default6">20</Radio.Button>
                    <Radio.Button value="default7">25</Radio.Button>
                </Radio.Group>


            </List.Item>
            <p style={{
                position: 'absolute',
                marginTop: -40,
                marginLeft: 5,
                fontSize: '9px',
                color: '#757070'
            }}>{name} (kHz)</p>
        </div>
    );
};

// function App() {


//   useEffect(() => {
//     (async() => {
//       (navigator as any)?.serial
//     .requestPort()
//     .then((port: any) => {
//       // Connect to `port` or add it to the list of available ports.
//       console.log(port);
//     })
//     .catch((e: any) => {
//       console.log(e);
//       // The user didn't select a port.
//     });
//     })().catch();
//   }, []);


//   return (
//     <div className="App">
//       {/* <header className="App-header"> */}
//       <Tabs
//         defaultActiveKey="1"
//         centered
//         items={new Array(3).fill(null).map((_, i) => {
//           const id = String(i + 1);
//           return {
//             label: `Settings ${id}`,
//             key: id,
//             // children: `Content of Tab Pane ${id}`,
//             children: <AppMenu />,
//           };
//         })}
//       />
//       <AppCard />
//       {/* </header> */}
//     </div>
//   );
// }

const {defaultAlgorithm, darkAlgorithm} = theme;

webSerialPort.init();

const App: React.FC = () => {
    const [isDarkMode, setIsDarkMode] = useState(true);

    useEffect(() => {

    },[]);

    return (
        <ConfigProvider
            theme={{
                algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
                components: {
                    Slider: {
                        handleSize: 12,
                        handleSizeHover: 14,
                        handleActiveColor: "#ff0000",
                        dotActiveBorderColor: "#ff0000",
                        railSize: 4,
                    }
                }
            }}>
            <Space direction="vertical" style={{width: '100%', maxWidth: '100vw', minHeight: '100vh'}} size={[0, 48]}>
                <Layout>
                        <Header style={{color: isDarkMode ? "#ffffff" : "#000000", backgroundColor: isDarkMode ? "#000000" : "#ffffff"}}>
                            <Flex gap="middle" justify="flex-start" align="center">
                            <img src="logo192.svg" width="40" height="40" style={{marginLeft: -30 }} />
                            <p style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold'}}>YAESU FT-891 Manager</p>

                                {/*<Switch checked={isDarkMode}*/}
                                {/*                       onChange={(checked) => setIsDarkMode(checked)} /><AppMenu/>*/}

                        <Button onClick={() => webSerialPort.connect()}>Connect</Button>
                            </Flex>
                        </Header>

                            <FTM891 />


                        <Footer style={footerStyle}>© {(new Date).getFullYear()} Marcin Kopa</Footer>
                </Layout>
            </Space>
        </ConfigProvider>

    )
};

export default App;
