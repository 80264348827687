import React, {useEffect, useState} from "react";
import SliderComponent from "../../ui/slider/slider-component";
import {AgcComponentType, AgcSectionProps} from "./agc-sections.types";
import {Button, List} from "antd";

const AgcSection = ({agcFastDelay, agcMidDelay, agcSlowDelay, onAgcFastDelayChange, onAgcMidDelayChange, onAgcSlowDelayChange}: AgcSectionProps) => {
  const [fastDelay, setFastDelay] = useState(agcFastDelay);
  const [midDelay, setMidDelay] = useState(agcMidDelay);
  const [slowDelay, setSlowDelay] = useState(agcSlowDelay);

  useEffect(() =>{
    setFastDelay(() => agcFastDelay);
  }, [agcFastDelay]);

  useEffect(() =>{
    setMidDelay(() => agcMidDelay);
  }, [agcMidDelay]);

  useEffect(() =>{
    setSlowDelay(() => agcSlowDelay);
  }, [agcSlowDelay]);

  const onChange = (componentType: AgcComponentType, value: number | null) => {
    switch (componentType) {
      case 'FAST_DELAY_SLIDER_COMPONENT':
        setFastDelay(() => value ?? fastDelay);
        if (onAgcFastDelayChange && fastDelay !== value) {
          onAgcFastDelayChange(value ?? agcFastDelay);
        }
        break;
      case 'MID_DELAY_SLIDER_COMPONENT':
        setMidDelay(() => value ?? midDelay);
        if (onAgcMidDelayChange && midDelay !== value) {
          onAgcMidDelayChange(value ?? agcMidDelay);
        }
        break;
      case 'SLOW_DELAY_SLIDER_COMPONENT':
        setSlowDelay(() => value ?? slowDelay);
        if (onAgcSlowDelayChange && slowDelay !== value) {
          onAgcSlowDelayChange(value ?? agcSlowDelay);
        }
        break;
      default: break;
    }
    console.log(componentType, value);
  };

  return (
      <div>
       <List
       size="small"
       // header={<div>
       //   <Button>READ</Button>
       //   <Button style={{marginLeft: 10}}>WRITE</Button>
       // </div>}
       style={ {margin: "0 0 0 0", padding: "0 0 0 0"}}
       // footer={<Button>Set to default</Button>}
       bordered
       children={<>
         <SliderComponent label='01-01&rarr;AGC FAST DELAY' unit='milliseconds' value={fastDelay} onChangeValue={(value) => onChange("FAST_DELAY_SLIDER_COMPONENT", value)} maxValue={4000} minValue={20} stepValue={20} />
         <SliderComponent label='01-02&rarr;AGC MID DELAY' unit='milliseconds' value={midDelay} onChangeValue={(value) => onChange("MID_DELAY_SLIDER_COMPONENT", value)} maxValue={4000} minValue={20} stepValue={20} />
         <SliderComponent label='01-03&rarr;AGC SLOW DELAY' unit='milliseconds' value={slowDelay} onChangeValue={(value) => onChange("SLOW_DELAY_SLIDER_COMPONENT", value)} maxValue={4000} minValue={20} stepValue={20} />

       </>}
     />

       {/*</List>*/}
      </div>
  );

};

export default AgcSection;