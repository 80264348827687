import React, {useEffect, useState} from "react";
import SliderComponent from "../../ui/slider/slider-component";
import {List} from "antd";
import {DisplaySectionProps} from "./display-sections.types";
import RadioComponent from "../../ui/radio/radio-component";
import {
    TYPE_CAT_EX_0201_DISPLAY_CONTRAST,
    TYPE_CAT_EX_0205_PEAK_HOLD,
    TYPE_CAT_EX_0206_ZIN_LED,
    TYPE_CAT_EX_0207_POPUP_MENU
} from "../../../api/api-service.types";

const DisplaySection = ({
                            lcdContrast,
                            dimmerBacklit,
                            dimmerLcd,
                            peakHold,
                            zinLed,
                            popupMenu,
                            dimmerTxBusy,
                            onLcdContrastChange,
                            onDimmerBacklitChange,
                            onDimmerLcdChange,
                            onDimmerTxBusyChange,
                            onPeakHoldChange,
                            onZinLedChange,
                            onPopupMenuChange,
                        }: DisplaySectionProps) => {
    const [displayLcdContrast, setDisplayLcdContrast] = useState(lcdContrast);
    const [displayDimmerBacklit, setDisplayDimmerBacklit] = useState(dimmerBacklit);
    const [displayDimmerLcd, setDisplayDimmerLcd] = useState(dimmerLcd);
    const [displayDimmerTxBusy, setDisplayDimmerTxBusy] = useState(dimmerTxBusy);
    const [displayPeakHold, setDisplayPeakHold] = useState<TYPE_CAT_EX_0205_PEAK_HOLD>("OFF");
    const [displayZinLed, setDisplayZinLed] = useState<TYPE_CAT_EX_0206_ZIN_LED>("DISABLE");
    const [displayPopupMenu, setDisplayPopupMenu] = useState<TYPE_CAT_EX_0207_POPUP_MENU>("LOWER");

    useEffect(() => {
        setDisplayLcdContrast(() => lcdContrast);
    }, [lcdContrast]);

    useEffect(() => {
        setDisplayDimmerBacklit(() => dimmerBacklit);
    }, [dimmerBacklit]);

    useEffect(() => {
        setDisplayDimmerLcd(() => dimmerLcd);
    }, [dimmerLcd]);

    useEffect(() => {
        setDisplayDimmerTxBusy(() => dimmerTxBusy);
    }, [dimmerTxBusy]);

    useEffect(() => {
        setDisplayPeakHold(() => peakHold);
    }, [peakHold]);

    useEffect(() => {
        setDisplayZinLed(() => zinLed);
    }, [zinLed]);

    useEffect(() => {
        setDisplayPopupMenu(() => popupMenu);
    }, [popupMenu]);


    const onChangeLcdContrast = (value: number | null) => {
        if (value !== displayLcdContrast) {
            setDisplayLcdContrast(() => value ?? lcdContrast);
            if (onLcdContrastChange) onLcdContrastChange(value ?? lcdContrast);
        }
    };

    const onChangeDimmerBacklit = (value: number | null) => {
        if (value !== displayDimmerBacklit) {
            setDisplayDimmerBacklit(() => value ?? dimmerBacklit);
            if (onDimmerBacklitChange) onDimmerBacklitChange(value ?? dimmerBacklit);
        }
    };

    const onChangeDimmerLcd = (value: number | null) => {
        if (value !== displayDimmerLcd) {
            setDisplayDimmerLcd(() => value ?? dimmerLcd);
            if (onDimmerLcdChange) onDimmerLcdChange(value ?? dimmerLcd);
        }
    };

    const onChangeDimmerTxBusy = (value: number | null) => {
        if (value !== displayDimmerTxBusy) {
            setDisplayDimmerTxBusy(() => value ?? dimmerTxBusy);
            if (onDimmerTxBusyChange) onDimmerTxBusyChange(value ?? dimmerTxBusy);
        }
    };

    const onChangePeakHold = (value: TYPE_CAT_EX_0205_PEAK_HOLD | null) => {
        if (value !== displayPeakHold) {
            setDisplayPeakHold(() => value ?? peakHold);
            if (onPeakHoldChange) onPeakHoldChange(value ?? peakHold);
        }
    };

    const onChangeZinLed = (value: TYPE_CAT_EX_0206_ZIN_LED | null) => {
        if (value !== displayZinLed) {
            setDisplayZinLed(() => value ?? zinLed);
            if (onZinLedChange) onZinLedChange(value ?? zinLed);
        }
    };

    const onChangePopupMenu = (value: TYPE_CAT_EX_0207_POPUP_MENU | null) => {
        if (value !== displayPopupMenu) {
            setDisplayPopupMenu(() => value ?? popupMenu);
            if (onPopupMenuChange) onPopupMenuChange(value ?? popupMenu);
        }
    };

    return (<List
        size="small"
        bordered
    >
        <SliderComponent label='02-01&rarr;LCD CONTRAST' value={displayLcdContrast}
                         onChangeValue={(value) => onChangeLcdContrast(value)} maxValue={15} minValue={1}
                         stepValue={1}/>
        <SliderComponent label='02-02&rarr;DIMMER BACKLIT' value={displayDimmerBacklit}
                         onChangeValue={(value) => onChangeDimmerBacklit(value)} maxValue={15} minValue={1}
                         stepValue={1}/>
        <SliderComponent label='02-03&rarr;DIMMER LCD' value={displayDimmerLcd}
                         onChangeValue={(value) => onChangeDimmerLcd(value)} maxValue={15} minValue={1} stepValue={1}/>
        <SliderComponent label='02-04&rarr;DIMMER TX/BUSY' value={displayDimmerTxBusy}
                         onChangeValue={(value) => onChangeDimmerTxBusy(value)} maxValue={15} minValue={1}
                         stepValue={1}/>
        <RadioComponent<TYPE_CAT_EX_0205_PEAK_HOLD> label='02-05&rarr;PEAK HOLD' value={displayPeakHold}
                                                    onChangeValue={(value) => onChangePeakHold(value)} items={[
            {name: "OFF", value: "OFF"},
            {name: "0.5s", value: "0.5s"},
            {name: "1.0s", value: "1.0s"},
            {name: "2.0s", value: "2.0s"},
        ]}/>
        <RadioComponent<TYPE_CAT_EX_0206_ZIN_LED> label='02-06&rarr;ZIN LED' value={displayZinLed}
                                                  onChangeValue={(value) => onChangeZinLed(value)} items={[
            {name: "DISABLE", value: "DISABLE"},
            {name: "ENABLE", value: "ENABLE"},
        ]}/>
        <RadioComponent<TYPE_CAT_EX_0207_POPUP_MENU> label='02-06&rarr;POP-UP MENU' value={displayPopupMenu}
                                                     onChangeValue={(value) => onChangePopupMenu(value)} items={[
            {name: "LOWER", value: "LOWER"},
                                                         {name: "UPPER", value: "UPPER"},
        ]}/>
    </List>);

};

export default DisplaySection;