import React, {useEffect, useState} from "react";
import {SliderMarks} from "antd/es/slider";
import {InputNumber, List, Slider} from "antd";
import LabelComponent from "../label/label-component";
import {calculateNewValue} from "@testing-library/user-event/dist/utils";
import {SliderComponentProps} from "./slider-component.types";



const SliderComponent: React.FC<SliderComponentProps> = ({ label, unit, value, stepValue = 1, minValue, maxValue, defaultValue = value, disabled=false, onChangeValue }: SliderComponentProps) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() =>{
        setInputValue(value);
    }, [value]);


    const onChange = (newValue: number | null) => {
        setInputValue(newValue ?? defaultValue);
        if (onChangeValue && value !== newValue) {
            onChangeValue(newValue ?? defaultValue);
        }
    };

    return (


        <List.Item actions={[<InputNumber

            min={minValue}
            max={maxValue}
            step={stepValue}
            defaultValue={defaultValue}
            style={{ margin: '0px 0px 0px 0px', maxWidth: '60px' }}
            value={inputValue}
            onChange={onChange}
            disabled={disabled}
            // name={name}
        />]}>
            <Slider
                min={minValue}
                max={maxValue}
                step={stepValue}
                defaultValue={defaultValue}
                onChange={onChange}
                value={typeof inputValue === 'number' ? inputValue : 0}
                disabled={disabled}
                style={{ width: "100%" }}
                // marks={marks}
                included={false}
            />
            <LabelComponent label={label} unit={unit} />
        </List.Item>
    );
};

export default SliderComponent;