import React, {useEffect, useState} from "react";
import SliderComponent from "../../ui/slider/slider-component";
import {KeyerSectionProps} from "./keyer-sections.types";
import {List} from "antd";
import {
    TYPE_CAT_EX_0401_KEYER_TYPE,
    TYPE_CAT_EX_0402_KEYER_DOT_DASH,
    TYPE_CAT_EX_0403_CW_WEIGHT,
    TYPE_CAT_EX_0404_BEACON_INTERVAL,
    TYPE_CAT_EX_0405_NUMBER_STYLE,
    TYPE_CAT_EX_0406_CONTEST_NUMBER,
    TYPE_CAT_EX_0407_CW_MEMORY_1,
    TYPE_CAT_EX_0408_CW_MEMORY_2,
    TYPE_CAT_EX_0409_CW_MEMORY_3, TYPE_CAT_EX_0410_CW_MEMORY_4, TYPE_CAT_EX_0411_CW_MEMORY_5
} from "../../../api/api-service.types";
import RadioComponent from "../../ui/radio/radio-component";

const KeyerSection = ({
                          keyerType,
                          keyerDotDash,
                          cwWeight,
                          beaconInterval,
                          numberStyle,
                          contestNumber,
                          cwMemory1,
                          cwMemory2,
                          cwMemory3,
                          cwMemory4,
                          cwMemory5,
                          onKeyerTypeChange,
                          onKeyerDotDashChange,
                          onCwWeightChange,
                          onBeaconIntervalChange,
                          onNumberStyleChange,
                          onContestNumberChange,
                          onCwMemory1Change,
                          onCwMemory2Change,
                          onCwMemory3Change,
                          onCwMemory4Change,
                          onCwMemory5Change,
                      }: KeyerSectionProps) => {
    const [localKeyerType, setKeyerTypeLocal] = useState(keyerType);
    const [localKeyerDotDash, setKeyerDotDashLocal] = useState(keyerDotDash);
    const [localCwWeight, setCwWeightLocal] = useState(cwWeight);
    const [localBeaconInterval, setBeaconIntervalLocal] = useState(beaconInterval);
    const [localNumberStyle, setNumberStyleLocal] = useState(numberStyle);
    const [localContestNumber, setContestNumberLocal] = useState(contestNumber);
    const [localCwMemory1, setCwMemory1Local] = useState(cwMemory1);
    const [localCwMemory2, setCwMemory2Local] = useState(cwMemory2);
    const [localCwMemory3, setCwMemory3Local] = useState(cwMemory3);
    const [localCwMemory4, setCwMemory4Local] = useState(cwMemory4);
    const [localCwMemory5, setCwMemory5Local] = useState(cwMemory5);


    useEffect(() => {
        setKeyerTypeLocal(() => keyerType);
    }, [keyerType]);

    useEffect(() => {
        setKeyerDotDashLocal(() => keyerDotDash);
    }, [keyerDotDash]);

    useEffect(() => {
        setCwWeightLocal(() => cwWeight);
    }, [cwWeight]);

    useEffect(() => {
        setBeaconIntervalLocal(() => beaconInterval);
    }, [beaconInterval]);

    useEffect(() => {
        setNumberStyleLocal(() => numberStyle);
    }, [numberStyle]);

    useEffect(() => {
        setContestNumberLocal(() => contestNumber);
    }, [contestNumber]);

    useEffect(() => {
        setCwMemory1Local(() => cwMemory1);
    }, [cwMemory1]);

    useEffect(() => {
        setCwMemory2Local(() => cwMemory2);
    }, [cwMemory2]);

    useEffect(() => {
        setCwMemory3Local(() => cwMemory3);
    }, [cwMemory3]);

    useEffect(() => {
        setCwMemory4Local(() => cwMemory4);
    }, [cwMemory4]);

    useEffect(() => {
        setCwMemory5Local(() => cwMemory5);
    }, [cwMemory5]);

    const onChangeKeyerType = (value: TYPE_CAT_EX_0401_KEYER_TYPE | null) => {
        setKeyerTypeLocal(() => value ?? keyerType);
        if (onKeyerTypeChange && localKeyerType !== value) {
            onKeyerTypeChange(value ?? keyerType);
        }
    }

    const onChangeKeyerDotDash = (value: TYPE_CAT_EX_0402_KEYER_DOT_DASH | null) => {
        setKeyerDotDashLocal(() => value ?? keyerDotDash);
        if (onKeyerDotDashChange && localKeyerDotDash !== value) {
            onKeyerDotDashChange(value ?? keyerDotDash);
        }
    }

    const onChangeCwWeight = (value: TYPE_CAT_EX_0403_CW_WEIGHT | null) => {
        setCwWeightLocal(() => value ?? cwWeight);
        if (onCwWeightChange && localCwWeight !== value) {
            onCwWeightChange(value ?? cwWeight);
        }
    }

    const onChangeBeaconInterval = (value: TYPE_CAT_EX_0404_BEACON_INTERVAL | null) => {
        setBeaconIntervalLocal(() => value ?? beaconInterval);
        if (onBeaconIntervalChange && localBeaconInterval !== value) {
            onBeaconIntervalChange(value ?? beaconInterval);
        }
    }

    const onChangeNumberStyle = (value: TYPE_CAT_EX_0405_NUMBER_STYLE | null) => {
        setNumberStyleLocal(() => value ?? numberStyle);
        if (onNumberStyleChange && localNumberStyle !== value) {
            onNumberStyleChange(value ?? numberStyle);
        }
    }

    const onChangeContestNumber = (value: TYPE_CAT_EX_0406_CONTEST_NUMBER | null) => {
        setContestNumberLocal(() => value ?? contestNumber);
        if (onContestNumberChange && localContestNumber !== value) {
            onContestNumberChange(value ?? contestNumber);
        }
    }

    const onChangeCwMemory2 = (value: TYPE_CAT_EX_0408_CW_MEMORY_2 | null) => {
        setCwMemory2Local(() => value ?? cwMemory2);
        if (onCwMemory2Change && localCwMemory2 !== value) {
            onCwMemory2Change(value ?? cwMemory2);
        }
    }

    const onChangeCwMemory3 = (value: TYPE_CAT_EX_0409_CW_MEMORY_3 | null) => {
        setCwMemory3Local(() => value ?? cwMemory3);
        if (onCwMemory3Change && localCwMemory3 !== value) {
            onCwMemory3Change(value ?? cwMemory3);
        }
    }

    const onChangeCwMemory4 = (value: TYPE_CAT_EX_0410_CW_MEMORY_4 | null) => {
        setCwMemory4Local(() => value ?? cwMemory4);
        if (onCwMemory4Change && localCwMemory4 !== value) {
            onCwMemory4Change(value ?? cwMemory4);
        }
    }

    const onChangeCwMemory5 = (value: TYPE_CAT_EX_0411_CW_MEMORY_5 | null) => {
        setCwMemory5Local(() => value ?? cwMemory5);
        if (onCwMemory5Change && localCwMemory5 !== value) {
            onCwMemory5Change(value ?? cwMemory5);
        }
    }

    const onChangeCwMemory1 = (value: TYPE_CAT_EX_0407_CW_MEMORY_1 | null) => {
        setCwMemory1Local(() => value ?? cwMemory1);
        if (onCwMemory1Change && localCwMemory1 !== value) {
            onCwMemory1Change(value ?? cwMemory1);
        }
    }

    return (<List
        size="small"
        bordered
    >
        <RadioComponent<TYPE_CAT_EX_0401_KEYER_TYPE> label='04-01&rarr;KEYER TYPE' value={localKeyerType}
                                                     onChangeValue={(value) => onChangeKeyerType(value)} items={[
            {name: "OFF", value: "OFF"},
            {name: "BUG", value: "BUG"},
            {name: "ELEKEY-A", value: "ELEKEY-A"},
            {name: "ELEKEY-B", value: "ELEKEY-B"},
            {name: "ELEKEY-Y", value: "ELEKEY-Y"},
            {name: "ACS", value: "ACS"},
        ]}/>
        <RadioComponent<TYPE_CAT_EX_0402_KEYER_DOT_DASH> label='04-02&rarr;KEYER DOT/DASH' value={localKeyerDotDash}
                                                         onChangeValue={(value) => onChangeKeyerDotDash(value)} items={[
            {name: "NOR", value: "NOR"},
            {name: "REV", value: "REV"},
        ]}/>
        <SliderComponent label='04-03&rarr;CW WEIGHT' value={localCwWeight}
                         onChangeValue={(value) => onChangeCwWeight(value)} maxValue={4.5} minValue={2.5}
                         stepValue={0.1}/>
        <SliderComponent label='04-04&rarr;BEACON INTERVAL' value={localBeaconInterval}
                         onChangeValue={(value) => onChangeBeaconInterval(value)} maxValue={690} minValue={0}
                         stepValue={1}/>
        <RadioComponent<TYPE_CAT_EX_0405_NUMBER_STYLE> label='04-05&rarr;NUMBER STYLE' value={localNumberStyle}
                                                       onChangeValue={(value) => onChangeNumberStyle(value)} items={[
            {name: "1290", value: "1290"},
            {name: "AUNO", value: "AUNO"},
            {name: "AUNT", value: "AUNT"},
            {name: "A2NO", value: "A2NO"},
            {name: "A2NT", value: "A2NT"},
            {name: "12NO", value: "12NO"},
            {name: "12NT", value: "12NT"},
        ]}/>
        <SliderComponent label='04-06&rarr;CONTEST NUMBER' value={localContestNumber}
                         onChangeValue={(value) => onChangeContestNumber(value)} maxValue={9999} minValue={0}
                         stepValue={1}/>
        <RadioComponent<TYPE_CAT_EX_0407_CW_MEMORY_1> label='04-07&rarr;CW MEMORY 1' value={localCwMemory1}
                                                      onChangeValue={(value) => onChangeCwMemory1(value)} items={[
            {name: "TEXT", value: "TEXT"},
            {name: "MESSAGE", value: "MESSAGE"},
        ]}/>
        <RadioComponent<TYPE_CAT_EX_0408_CW_MEMORY_2> label='04-08&rarr;CW MEMORY 2' value={localCwMemory2}
                                                      onChangeValue={(value) => onChangeCwMemory2(value)} items={[
            {name: "TEXT", value: "TEXT"},
            {name: "MESSAGE", value: "MESSAGE"},
        ]}/>
        <RadioComponent<TYPE_CAT_EX_0409_CW_MEMORY_3> label='04-09&rarr;CW MEMORY 3' value={localCwMemory3}
                                                      onChangeValue={(value) => onChangeCwMemory3(value)} items={[
            {name: "TEXT", value: "TEXT"},
            {name: "MESSAGE", value: "MESSAGE"},
        ]}/>
        <RadioComponent<TYPE_CAT_EX_0410_CW_MEMORY_4> label='04-10&rarr;CW MEMORY 4' value={localCwMemory4}
                                                      onChangeValue={(value) => onChangeCwMemory4(value)} items={[
            {name: "TEXT", value: "TEXT"},
            {name: "MESSAGE", value: "MESSAGE"},
        ]}/>
        <RadioComponent<TYPE_CAT_EX_0411_CW_MEMORY_5> label='04-11&rarr;CW MEMORY 5' value={localCwMemory5}
                                                      onChangeValue={(value) => onChangeCwMemory5(value)} items={[
            {name: "TEXT", value: "TEXT"},
            {name: "MESSAGE", value: "MESSAGE"},
        ]}/>
    </List>);
};

export default KeyerSection;