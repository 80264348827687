import React from "react";
import {Button, Checkbox, Collapse, Switch, Tabs} from "antd";
import AgcSection from "../../components/logic/01-agc-section/agc-section";
import DisplaySection from "../../components/logic/02-display-section/display-section";
import {Content} from "antd/es/layout/layout";
import {webSerialPort} from "../../api/web-serial-port/w-serial-port";
import {CAT_COMMAND} from "../../api/api-service.types";
import {ApiService} from "../../api/api-service";
import {SettingOutlined} from "@ant-design/icons";
import DvsSection from "../../components/logic/03-dvs-section/dvs-section";
import KeyerSection from "../../components/logic/04-keyer-section/keyer-section";

const contentStyle: React.CSSProperties = {
    // minHeight: '100vh',
    padding: 10,
    marginTop: -17,
};

const apiService = ApiService(webSerialPort);
export const FTM891: React.FC = () => {


    const onChange = (type: CAT_COMMAND, value: any) => {
        apiService.writeCAT<any>(type, value).catch((e) => {
            // alert("Port is not open");
        })
    }

    const genExtra = () => (
        <div>
            lock
            <span onClick={e => {
                e.preventDefault();
                e.stopPropagation();
            }}>
        <Switch size="default" style={{marginLeft: 10}}/>
        </span>
        </div>
    );

    const mainMenu = (<Content style={contentStyle}>
        <Content style={{display: "flex", marginBottom: 10, flexDirection: "row"}}>
            <div style={{display: "flex", flexShrink: 0}}>
                <Button>Load</Button>
                <Button style={{marginLeft: 10}}>Save</Button>
            </div>
            {/*<div style={{display: "flex", flex: 1}}></div>*/}
            {/*<div style={{display: "flex", flexShrink: 0}}>*/}
            {/*    <Button type="default">Write</Button>*/}
            {/*    <Button style={{marginLeft: 10}}>Read</Button>*/}
            {/*</div>*/}
        </Content>
        <Collapse defaultActiveKey={[/*'1', '2'*/'3']} items={[{
            // extra: genExtra(),
            key: '1',
            label: '01 AGC',
            children: <AgcSection name="AGC FAST" agcFastDelay={300} agcMidDelay={700}
                                  agcSlowDelay={3000}
                                  onAgcFastDelayChange={(value) => onChange(CAT_COMMAND.CAT_EX_01_AGC_FAST_DELAY, value)}
                                  onAgcMidDelayChange={(value) => onChange(CAT_COMMAND.CAT_EX_01_AGC_MID_DELAY, value)}
                                  onAgcSlowDelayChange={(value) => onChange(CAT_COMMAND.CAT_EX_01_AGC_SLOW_DELAY, value)}/>
        },
            {
                key: '2',
                label: '02 DISPLAY',
                children: <DisplaySection lcdContrast={8} dimmerBacklit={8} dimmerLcd={8}
                                          dimmerTxBusy={8} peakHold={"OFF"} zinLed={"DISABLE"} popupMenu={"LOWER"}
                                          onLcdContrastChange={(value) => onChange(CAT_COMMAND.CAT_EX_02_DISPLAY_CONTRAST, value)}
                                          onDimmerBacklitChange={(value) => onChange(CAT_COMMAND.CAT_EX_02_DIMMER_BACKLIT, value)}
                                          onDimmerLcdChange={(value) => onChange(CAT_COMMAND.CAT_EX_02_DIMMER_LCD, value)}
                                          onDimmerTxBusyChange={(value) => onChange(CAT_COMMAND.CAT_EX_02_DIMMER_TX_BUSY, value)}
                                          onPeakHoldChange={(value) => onChange(CAT_COMMAND.CAT_EX_02_PEAK_HOLD, value)}
                                          onZinLedChange={(value) => onChange(CAT_COMMAND.CAT_EX_02_ZIN_LED, value)}
                                          onPopupMenuChange={(value) => onChange(CAT_COMMAND.CAT_EX_02_POPUP_MENU, value)}
                />
            },
            {
                // extra: genExtra(),
                key: '3',
                label: '03 DVS',
                children: <DvsSection dvsRxOutLevel={50} dvsTxOutLevel={50}
                                      onDvsRxOutLevelChange={(value) => onChange(CAT_COMMAND.CAT_EX_03_DVS_RX_OUT_LVL, value)}
                                      onDvsTxOutLevelChange={(value) => onChange(CAT_COMMAND.CAT_EX_03_DVS_TX_OUT_LVL, value)}/>
            },
            {
                // extra: genExtra(),
                key: '4',
                label: '04 KEYER',
                children: <KeyerSection keyerType={"ELEKEY-B"} keyerDotDash={"NOR"} cwWeight={3.0} beaconInterval={0} numberStyle={"1290"}
                                        contestNumber={1} cwMemory1={"TEXT"} cwMemory2={"TEXT"} cwMemory3={"TEXT"} cwMemory4={"TEXT"} cwMemory5={"TEXT"}
                                      onKeyerTypeChange={(value) => onChange(CAT_COMMAND.CAT_EX_04_KEYER_TYPE, value)}
                                      onKeyerDotDashChange={(value) => onChange(CAT_COMMAND.CAT_EX_04_KEYER_DOT_DASH, value)}
                                      onCwWeightChange={(value) => onChange(CAT_COMMAND.CAT_EX_04_CW_WEIGHT, value)}
                                      onBeaconIntervalChange={(value) => onChange(CAT_COMMAND.CAT_EX_04_BEACON_INTERVAL, value)}
                                      onNumberStyleChange={(value) => onChange(CAT_COMMAND.CAT_EX_04_NUMBER_STYLE, value)}
                                      onContestNumberChange={(value) => onChange(CAT_COMMAND.CAT_EX_04_CONTEST_NUMBER, value)}
                                      onCwMemory1Change={(value) => onChange(CAT_COMMAND.CAT_EX_04_CW_MEMORY_1, value)}
                                      onCwMemory2Change={(value) => onChange(CAT_COMMAND.CAT_EX_04_CW_MEMORY_2, value)}
                                      onCwMemory3Change={(value) => onChange(CAT_COMMAND.CAT_EX_04_CW_MEMORY_3, value)}
                                      onCwMemory4Change={(value) => onChange(CAT_COMMAND.CAT_EX_04_CW_MEMORY_4, value)}
                                      onCwMemory5Change={(value) => onChange(CAT_COMMAND.CAT_EX_04_CW_MEMORY_5, value)}/>
            },]}/>
        {/*<Collapse items={[{ key: '3', label: '03 AGC', children: <AgcSection name="AGC FAST" agcFastDelay={300} agcMidDelay={300} agcSlowDelay={300} /> }]} />*/}
        {/*<Collapse items={[{ key: '4', label: '04 AGC', children: <AgcSection name="AGC FAST" agcFastDelay={300} agcMidDelay={300} agcSlowDelay={300} /> }]} />*/}
    </Content>);

    return (<Content style={{marginTop: 10}}>
        {/*<></>*/}
        <Tabs type="card" defaultActiveKey="1" items={[{key: "1", label: "Menu", children: mainMenu}]}/></Content>);
}