import {
    ApiProvider,
    CAT_COMMAND,
    TYPE_CAT_EX_0101_AGC_FAST_DELAY,
    TYPE_CAT_EX_0102_AGC_MID_DELAY,
    TYPE_CAT_EX_0103_AGC_SLOW_DELAY,
    TYPE_CAT_EX_0201_DISPLAY_CONTRAST,
    TYPE_CAT_EX_0202_DIMMER_BACKLIT,
    TYPE_CAT_EX_0203_DIMMER_LCD,
    TYPE_CAT_EX_0204_DIMMER_TX_BUSY,
    TYPE_CAT_EX_0205_PEAK_HOLD,
    TYPE_CAT_EX_0206_ZIN_LED,
    TYPE_CAT_EX_0207_POPUP_MENU,
    TYPE_CAT_EX_0301_DVS_RX_OUT_LVL,
    TYPE_CAT_EX_0302_DVS_TX_OUT_LVL,
    TYPE_CAT_EX_0401_KEYER_TYPE,
    TYPE_CAT_EX_0402_KEYER_DOT_DASH,
    TYPE_CAT_EX_0403_CW_WEIGHT,
    TYPE_CAT_EX_0404_BEACON_INTERVAL,
    TYPE_CAT_EX_0405_NUMBER_STYLE,
    TYPE_CAT_EX_0406_CONTEST_NUMBER,
    TYPE_CAT_EX_0407_CW_MEMORY_1,
    TYPE_CAT_EX_0408_CW_MEMORY_2,
    TYPE_CAT_EX_0409_CW_MEMORY_3,
    TYPE_CAT_EX_0410_CW_MEMORY_4,
    TYPE_CAT_EX_0411_CW_MEMORY_5,
    TYPE_CAT_EX_0501_NB_WIDTH,
    TYPE_CAT_EX_0502_NB_REJECTION,
    TYPE_CAT_EX_0503_NB_LEVEL,
    TYPE_CAT_EX_0504_NB_BEEP_LEVEL,
    TYPE_CAT_EX_0505_RF_SQL_VR,
    TYPE_CAT_EX_0506_CAT_RATE,
    TYPE_CAT_EX_0507_CAT_TOT,
    TYPE_CAT_EX_0508_CAT_RTS,
    TYPE_CAT_EX_0509_MEM_GROUP,
    TYPE_CAT_EX_0510_FM_SETTINGS,
    TYPE_CAT_EX_0511_REC_SETTINGS,
    TYPE_CAT_EX_0512_ATAS_SETTINGS,
    TYPE_CAT_EX_0513_QUICK_SPL_FREQ,
    TYPE_CAT_EX_0514_TX_TOT,
    TYPE_CAT_EX_0515_MIC_SCAN,
    TYPE_CAT_EX_0516_MIC_SCAN_RESUME,
    TYPE_CAT_EX_0517_REF_FREQ_ADJ,
    TYPE_CAT_EX_0518_CLEAR_SELECT,
    TYPE_CAT_EX_0519_APO,
    TYPE_CAT_EX_0520_FAN_CONTROL,
    TYPE_CAT_EX_0601_AM_LCUT_FREQ,
    TYPE_CAT_EX_0602_AM_LCUT_SLOPE,
    TYPE_CAT_EX_0603_AM_HCUT_FREQ,
    TYPE_CAT_EX_0604_AM_HCUT_SLOPE,
    TYPE_CAT_EX_0605_AM_MIC_SELECT,
    TYPE_CAT_EX_0606_AM_OUT_LEVEL,
    TYPE_CAT_EX_0607_AM_PTT_SELECT,
    TYPE_CAT_EX_0701_CW_LCUT_FREQ,
    TYPE_CAT_EX_0702_CW_LCUT_SLOPE,
    TYPE_CAT_EX_0703_CW_HCUT_FREQ,
    TYPE_CAT_EX_0704_CW_HCUT_SLOPE,
    TYPE_CAT_EX_0705_CW_OUT_LEVEL,
    TYPE_CAT_EX_0706_CW_AUTO_MODE,
    TYPE_CAT_EX_0707_CW_BFO,
    TYPE_CAT_EX_0708_CW_BKIN_TYPE,
    TYPE_CAT_EX_0709_CW_BKIN_DELAY,
    TYPE_CAT_EX_0710_CW_WAVE_SHAPE,
    TYPE_CAT_EX_0711_CW_FREQ_DISPLAY,
    TYPE_CAT_EX_0712_CW_PC_KEYING,
    TYPE_CAT_EX_0713_QSK_DELAY_TIME,
    TYPE_CAT_EX_0801_DATA_MODE,
    TYPE_CAT_EX_0802_PSK_TONE,
    TYPE_CAT_EX_0803_OTHER_DISP,
    TYPE_CAT_EX_0804_OTHER_SHIFT,
    TYPE_CAT_EX_0805_DATA_LCUT_FREQ,
    TYPE_CAT_EX_0806_DATA_LCUT_SLOPE,
    TYPE_CAT_EX_0807_DATA_HCUT_FREQ,
    TYPE_CAT_EX_0808_DATA_HCUT_SLOPE,
    TYPE_CAT_EX_0809_DATA_IN_SELECT,
    TYPE_CAT_EX_0810_DATA_PTT_SELECT,
    TYPE_CAT_EX_0811_DATA_OUT_LEVEL,
    TYPE_CAT_EX_0812_DATA_BFO,
    TYPE_CAT_EX_0901_FM_MIC_SELECT,
    TYPE_CAT_EX_0902_FM_OUTPUT_LEVEL,
    TYPE_CAT_EX_0903_PKT_PTT_SELECT,
    TYPE_CAT_EX_0904_RPT_SHIFT_28MHZ,
    TYPE_CAT_EX_0905_RPT_SHIFT_50MHZ,
    TYPE_CAT_EX_0906_DCS_POLARITY,
    TYPE_CAT_EX_1001_RTTY_LCUT_FREQ,
    TYPE_CAT_EX_1002_RTTY_LCUT_SLOPE,
    TYPE_CAT_EX_1003_RTTY_HCUT_FREQ,
    TYPE_CAT_EX_1004_RTTY_HCUT_SLOPE,
    TYPE_CAT_EX_1005_RTTY_SHIFT_PORT,
    TYPE_CAT_EX_1006_RTTY_POLARITY_R,
    TYPE_CAT_EX_1007_RTTY_POLARITY_L,
    TYPE_CAT_EX_1008_RTTY_OUT_LEVEL,
    TYPE_CAT_EX_1009_RTTY_SHIFT_FREQ,
    TYPE_CAT_EX_1010_RTTY_MARK_FREQ,
    TYPE_CAT_EX_1011_RTTY_BFO,
    TYPE_CAT_EX_1101_SSB_LCUT_FREQ,
    TYPE_CAT_EX_1102_SSB_LCUT_SLOPE,
    TYPE_CAT_EX_1103_SSB_HCUT_FREQ,
    TYPE_CAT_EX_1104_SSB_HCUT_SLOPE,
    TYPE_CAT_EX_1105_SSB_MIC_SELECT,
    TYPE_CAT_EX_1106_SSB_OUT_LEVEL,
    TYPE_CAT_EX_1107_SSB_BFO,
    TYPE_CAT_EX_1108_SSB_PTT_SELECT,
    TYPE_CAT_EX_1109_SSB_TX_BPF,
    TYPE_CAT_EX_1201_APF_WIDTH,
    TYPE_CAT_EX_1202_CONTOUR_LEVEL,
    TYPE_CAT_EX_1203_CONTOUR_WIDTH,
    TYPE_CAT_EX_1204_IF_NOTCH_WIDTH,
    TYPE_CAT_EX_1301_SCP_START_CYCLE,
    TYPE_CAT_EX_1302_SCP_SPAN_FREQ,
    TYPE_CAT_EX_1401_QUICK_DIAL,
    TYPE_CAT_EX_1402_SSB_DIAL_STEP,
    TYPE_CAT_EX_1403_AM_DIAL_STEP,
    TYPE_CAT_EX_1404_FM_DIAL_STEP,
    TYPE_CAT_EX_1405_DIAL_STEP,
    TYPE_CAT_EX_1406_AM_CH_STEP,
    TYPE_CAT_EX_1407_FM_CH_STEP,
    TYPE_CAT_EX_1501_EQ1_FREQ,
    TYPE_CAT_EX_1502_EQ1_LEVEL,
    TYPE_CAT_EX_1503_EQ1_BWTH,
    TYPE_CAT_EX_1504_EQ2_FREQ,
    TYPE_CAT_EX_1505_EQ2_LEVEL,
    TYPE_CAT_EX_1506_EQ2_BWTH,
    TYPE_CAT_EX_1507_EQ3_FREQ,
    TYPE_CAT_EX_1508_EQ3_LEVEL,
    TYPE_CAT_EX_1509_EQ3_BWTH,
    TYPE_CAT_EX_1510_PEQ1_FREQ,
    TYPE_CAT_EX_1511_PEQ1_LEVEL,
    TYPE_CAT_EX_1512_PEQ1_BWTH,
    TYPE_CAT_EX_1513_PEQ2_FREQ,
    TYPE_CAT_EX_1514_PEQ2_LEVEL,
    TYPE_CAT_EX_1515_PEQ2_BWTH,
    TYPE_CAT_EX_1516_PEQ3_FREQ,
    TYPE_CAT_EX_1517_PEQ3_LEVEL,
    TYPE_CAT_EX_1518_PEQ3_BWTH,
    TYPE_CAT_EX_1601_HF_SSB_PWR,
    TYPE_CAT_EX_1602_HF_AM_PWR,
    TYPE_CAT_EX_1603_HF_PWR,
    TYPE_CAT_EX_1604_50M_SSB_HF_PWR,
    TYPE_CAT_EX_1605_50M_AM_HF_PWR,
    TYPE_CAT_EX_1606_50M_HF_PWR,
    TYPE_CAT_EX_1607_SSB_MIC_GAIN,
    TYPE_CAT_EX_1608_AM_MIC_GAIN,
    TYPE_CAT_EX_1609_FM_MIC_GAIN,
    TYPE_CAT_EX_1610_DATA_MIC_GAIN,
    TYPE_CAT_EX_1611_SSB_DATA_GAIN,
    TYPE_CAT_EX_1612_AM_DATA_GAIN,
    TYPE_CAT_EX_1613_FM_DATA_GAIN,
    TYPE_CAT_EX_1614_DATA_DATA_GAIN,
    TYPE_CAT_EX_1615_TUNER_SELECT,
    TYPE_CAT_EX_1616_VOX_SELECT,
    TYPE_CAT_EX_1617_VOX_GAIN,
    TYPE_CAT_EX_1618_VOX_DELEAY,
    TYPE_CAT_EX_1619_ANTI_VOX_GAIN,
    TYPE_CAT_EX_1620_DATA_VOX_GAIN,
    TYPE_CAT_EX_1621_DATA_VOX_DELAY,
    TYPE_CAT_EX_1622_ANTI_DVOX_GAIN,
    TYPE_CAT_EX_1623_EMERGENCY_FREQ,
    TYPE_CAT_EX_1701_RESET,
    TYPE_CAT_EX_1801_MAIN_VERSION,
    TYPE_CAT_EX_1802_DSP_VERSION, TYPE_CAT_EX_1803_LCD_VERSION,
} from "./api-service.types";
import app from "../App";

const leftPad = (value: number, maxLength: number, fillString = "0") => value.toString().padStart(maxLength, fillString);

const FT891_DECODER = {
    // 01
    [CAT_COMMAND.CAT_EX_01_AGC_FAST_DELAY]: (data: string): TYPE_CAT_EX_0101_AGC_FAST_DELAY => parseInt(data, 10),
    [CAT_COMMAND.CAT_EX_01_AGC_MID_DELAY]: (data: string): TYPE_CAT_EX_0102_AGC_MID_DELAY => parseInt(data, 10),
    [CAT_COMMAND.CAT_EX_01_AGC_SLOW_DELAY]: (data: string): TYPE_CAT_EX_0103_AGC_SLOW_DELAY => parseInt(data, 10),
    // 02
    [CAT_COMMAND.CAT_EX_02_DISPLAY_CONTRAST]: (data: string): TYPE_CAT_EX_0201_DISPLAY_CONTRAST => parseInt(data, 10),
    [CAT_COMMAND.CAT_EX_02_DIMMER_BACKLIT]: (data: string): TYPE_CAT_EX_0202_DIMMER_BACKLIT => parseInt(data, 10),
    [CAT_COMMAND.CAT_EX_02_DIMMER_LCD]: (data: string): TYPE_CAT_EX_0203_DIMMER_LCD => parseInt(data, 10),
    [CAT_COMMAND.CAT_EX_02_DIMMER_TX_BUSY]: (data: string): TYPE_CAT_EX_0204_DIMMER_TX_BUSY => parseInt(data, 10),
    [CAT_COMMAND.CAT_EX_02_PEAK_HOLD]: (data: string): TYPE_CAT_EX_0205_PEAK_HOLD => {
        const value = parseInt(data, 10);
        if (value === 3) return "2.0s";
        if (value === 2) return "1.0s";
        if (value === 1) return "0.5s";
        return "OFF";
    },
    [CAT_COMMAND.CAT_EX_02_ZIN_LED]: (data: string): TYPE_CAT_EX_0206_ZIN_LED => parseInt(data, 10) === 1 ? "ENABLE" : "DISABLE",
    [CAT_COMMAND.CAT_EX_02_POPUP_MENU]: (data: string): TYPE_CAT_EX_0207_POPUP_MENU => parseInt(data, 10) === 1 ? "LOWER" : "UPPER",
    // 03
    [CAT_COMMAND.CAT_EX_03_DVS_RX_OUT_LVL]: (data: string): TYPE_CAT_EX_0301_DVS_RX_OUT_LVL => parseInt(data, 10),
    [CAT_COMMAND.CAT_EX_03_DVS_TX_OUT_LVL]: (data: string): TYPE_CAT_EX_0302_DVS_TX_OUT_LVL => parseInt(data, 10),
    // 04
    [CAT_COMMAND.CAT_EX_04_KEYER_TYPE]: (data: string): TYPE_CAT_EX_0401_KEYER_TYPE => {
        const value = parseInt(data, 10);
        if (value === 5) return "ACS";
        if (value === 4) return "ELEKEY-Y";
        if (value === 3) return "ELEKEY-B";
        if (value === 2) return "ELEKEY-A";
        if (value === 1) return "BUG";
        return "OFF";
    },
    [CAT_COMMAND.CAT_EX_04_KEYER_DOT_DASH]: (data: string): TYPE_CAT_EX_0402_KEYER_DOT_DASH => parseInt(data, 10) === 1 ? "REV" : "NOR",
    [CAT_COMMAND.CAT_EX_04_CW_WEIGHT]: (data: string): TYPE_CAT_EX_0403_CW_WEIGHT => parseInt(data, 10) / 10,
    [CAT_COMMAND.CAT_EX_04_BEACON_INTERVAL]: (data: string): TYPE_CAT_EX_0404_BEACON_INTERVAL => parseInt(data, 10),
    [CAT_COMMAND.CAT_EX_04_NUMBER_STYLE]: (data: string): TYPE_CAT_EX_0405_NUMBER_STYLE => {
        const value = parseInt(data, 10);
        if (value === 6) return "12NT";
        if (value === 5) return "12NO";
        if (value === 4) return "A2NT";
        if (value === 3) return "A2NO";
        if (value === 2) return "AUNT";
        if (value === 1) return "AUNO";
        return "1290";
    },
    [CAT_COMMAND.CAT_EX_04_CONTEST_NUMBER]: (data: string): TYPE_CAT_EX_0406_CONTEST_NUMBER => parseInt(data, 10),
    [CAT_COMMAND.CAT_EX_04_CW_MEMORY_1]: (data: string): TYPE_CAT_EX_0407_CW_MEMORY_1 => parseInt(data, 10) === 1 ? "MESSAGE" : "TEXT",
    [CAT_COMMAND.CAT_EX_04_CW_MEMORY_2]: (data: string): TYPE_CAT_EX_0408_CW_MEMORY_2 => parseInt(data, 10) === 1 ? "MESSAGE" : "TEXT",
    [CAT_COMMAND.CAT_EX_04_CW_MEMORY_3]: (data: string): TYPE_CAT_EX_0409_CW_MEMORY_3 => parseInt(data, 10) === 1 ? "MESSAGE" : "TEXT",
    [CAT_COMMAND.CAT_EX_04_CW_MEMORY_4]: (data: string): TYPE_CAT_EX_0410_CW_MEMORY_4 => parseInt(data, 10) === 1 ? "MESSAGE" : "TEXT",
    [CAT_COMMAND.CAT_EX_04_CW_MEMORY_5]: (data: string): TYPE_CAT_EX_0411_CW_MEMORY_5 => parseInt(data, 10) === 1 ? "MESSAGE" : "TEXT",

}

const FT891_ENCODER = {
    [CAT_COMMAND.CAT_EX_01_AGC_FAST_DELAY]: (data: TYPE_CAT_EX_0101_AGC_FAST_DELAY): string => leftPad(data, 4, "0"),
    [CAT_COMMAND.CAT_EX_01_AGC_MID_DELAY]: (data: TYPE_CAT_EX_0102_AGC_MID_DELAY): string => leftPad(data, 4, "0"),
    [CAT_COMMAND.CAT_EX_01_AGC_SLOW_DELAY]: (data: TYPE_CAT_EX_0103_AGC_SLOW_DELAY): string => data.toString().padStart(4, "0"),
    [CAT_COMMAND.CAT_EX_02_DISPLAY_CONTRAST]: (data: TYPE_CAT_EX_0201_DISPLAY_CONTRAST): string => data.toString().padStart(2, "0"),
    [CAT_COMMAND.CAT_EX_02_DIMMER_BACKLIT]: (data: TYPE_CAT_EX_0202_DIMMER_BACKLIT): string => data.toString().padStart(2, "0"),
    [CAT_COMMAND.CAT_EX_02_DIMMER_LCD]: (data: TYPE_CAT_EX_0203_DIMMER_LCD): string => data.toString().padStart(2, "0"),
    [CAT_COMMAND.CAT_EX_02_DIMMER_TX_BUSY]: (data: TYPE_CAT_EX_0204_DIMMER_TX_BUSY): string => data.toString().padStart(2, "0"),
    [CAT_COMMAND.CAT_EX_02_PEAK_HOLD]: (data: TYPE_CAT_EX_0205_PEAK_HOLD): string => {
        if (data === "0.5s") return "1";
        if (data === "1.0s") return "2";
        if (data === "2.0s") return "3";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_02_ZIN_LED]: (data: TYPE_CAT_EX_0206_ZIN_LED): string => data === "ENABLE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_02_POPUP_MENU]: (data: TYPE_CAT_EX_0207_POPUP_MENU): string => data === "LOWER" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_03_DVS_RX_OUT_LVL]: (data: TYPE_CAT_EX_0301_DVS_RX_OUT_LVL): string => data.toString().padStart(3, "0"),
    [CAT_COMMAND.CAT_EX_03_DVS_TX_OUT_LVL]: (data: TYPE_CAT_EX_0302_DVS_TX_OUT_LVL): string => data.toString().padStart(3, "0"),

    [CAT_COMMAND.CAT_EX_04_KEYER_TYPE]: (data: TYPE_CAT_EX_0401_KEYER_TYPE): string => {
        if (data === "ACS") return "5";
        if (data === "ELEKEY-Y") return "4";
        if (data === "ELEKEY-B") return "3";
        if (data === "ELEKEY-A") return "2";
        if (data === "BUG") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_04_KEYER_DOT_DASH]: (data: TYPE_CAT_EX_0402_KEYER_DOT_DASH): string => data === "REV" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_04_CW_WEIGHT]: (data: TYPE_CAT_EX_0403_CW_WEIGHT): string => (data * 10).toString().padStart(2, "0"),
    [CAT_COMMAND.CAT_EX_04_BEACON_INTERVAL]: (data: TYPE_CAT_EX_0404_BEACON_INTERVAL): string => data.toString().padStart(3, "0"),
    [CAT_COMMAND.CAT_EX_04_NUMBER_STYLE]: (data: TYPE_CAT_EX_0405_NUMBER_STYLE): string => {
        if (data === "12NT") return "6";
        if (data === "12NO") return "5";
        if (data === "A2NT") return "4";
        if (data === "A2NO") return "3";
        if (data === "AUNT") return "2";
        if (data === "AUNO") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_04_CONTEST_NUMBER]: (data: TYPE_CAT_EX_0406_CONTEST_NUMBER): string => data.toString().padStart(4, "0"),
    [CAT_COMMAND.CAT_EX_04_CW_MEMORY_1]: (data: TYPE_CAT_EX_0407_CW_MEMORY_1): string => data === "MESSAGE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_04_CW_MEMORY_2]: (data: TYPE_CAT_EX_0408_CW_MEMORY_2): string => data === "MESSAGE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_04_CW_MEMORY_3]: (data: TYPE_CAT_EX_0409_CW_MEMORY_3): string => data === "MESSAGE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_04_CW_MEMORY_4]: (data: TYPE_CAT_EX_0410_CW_MEMORY_4): string => data === "MESSAGE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_04_CW_MEMORY_5]: (data: TYPE_CAT_EX_0411_CW_MEMORY_5): string => data === "MESSAGE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_05_NB_REJECTION]: (data: TYPE_CAT_EX_0502_NB_REJECTION): string => {
        if (data === "50dB") return "2";
        if (data === "30dB") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_05_NB_WIDTH]: (data: TYPE_CAT_EX_0501_NB_WIDTH): string => {
        if (data === "10msec") return "2";
        if (data === "3msec") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_05_NB_LEVEL]: (data: TYPE_CAT_EX_0503_NB_LEVEL): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_05_BEEP_LEVEL]: (data: TYPE_CAT_EX_0504_NB_BEEP_LEVEL): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_05_RF_SQL_VR]: (data: TYPE_CAT_EX_0505_RF_SQL_VR): string => data === "SQL" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_05_CAT_RATE]: (data: TYPE_CAT_EX_0506_CAT_RATE): string => {
        if (data === "38400bps") return "3";
        if (data === "19200bps") return "2";
        if (data === "9600bps") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_05_CAT_TOT]: (data: TYPE_CAT_EX_0507_CAT_TOT): string => {
        if (data === "3000msec") return "3";
        if (data === "1000msec") return "2";
        if (data === "100msec") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_05_CAT_RTS]: (data: TYPE_CAT_EX_0508_CAT_RTS): string => data === "ENABLE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_05_MEM_GROUP]: (data: TYPE_CAT_EX_0509_MEM_GROUP): string => data === "ENABLE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_05_FM_SETTING]: (data: TYPE_CAT_EX_0510_FM_SETTINGS): string => data === "ENABLE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_05_REC_SETTING]: (data: TYPE_CAT_EX_0511_REC_SETTINGS): string => data === "ENABLE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_05_ATAS_SETTING]: (data: TYPE_CAT_EX_0512_ATAS_SETTINGS): string => data === "ENABLE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_05_QUICK_SPL_FREQ]: (data: TYPE_CAT_EX_0513_QUICK_SPL_FREQ): string => `${data < 0 ? "-" : "+"}${Math.abs(data).toString().padStart(2, "0")}`,
    [CAT_COMMAND.CAT_EX_05_TX_TOT]: (data: TYPE_CAT_EX_0514_TX_TOT): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_05_MIC_SCAN]: (data: TYPE_CAT_EX_0515_MIC_SCAN): string => data === "ENABLE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_05_MIC_SCAN_RESUME]: (data: TYPE_CAT_EX_0516_MIC_SCAN_RESUME): string => data === "TIME" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_05_REF_FREQ_ADJ]: (data: TYPE_CAT_EX_0517_REF_FREQ_ADJ): string => `${data < 0 ? "-" : "+"}${Math.abs(data).toString().padStart(2, "0")}`,
    [CAT_COMMAND.CAT_EX_05_CLEAR_SELECT]: (data: TYPE_CAT_EX_0518_CLEAR_SELECT): string => {
        if (data === "TRX") return "2";
        if (data === "TX") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_05_APO]: (data: TYPE_CAT_EX_0519_APO): string => {
        if (data === "12h") return "7";
        if (data === "10h") return "6";
        if (data === "8h") return "5";
        if (data === "6h") return "4";
        if (data === "4h") return "3";
        if (data === "2h") return "2";
        if (data === "1h") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_05_FAN_CONTROL]: (data: TYPE_CAT_EX_0520_FAN_CONTROL): string => data === "CONTEST" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_06_AM_LCUT_FREQ]: (data: TYPE_CAT_EX_0601_AM_LCUT_FREQ): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_06_AM_LCUT_SLOPE]: (data: TYPE_CAT_EX_0602_AM_LCUT_SLOPE): string => data === "18dB/oct" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_06_AM_HCUT_FREQ]: (data: TYPE_CAT_EX_0603_AM_HCUT_FREQ): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_06_AM_HCUT_SLOPE]: (data: TYPE_CAT_EX_0604_AM_HCUT_SLOPE): string => data === "18dB/oct" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_06_AM_MIC_SELECT]: (data: TYPE_CAT_EX_0605_AM_MIC_SELECT): string => data === "REAR" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_06_AM_OUT_LEVEL]: (data: TYPE_CAT_EX_0606_AM_OUT_LEVEL): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_06_AM_PTT_SELECT]: (data: TYPE_CAT_EX_0607_AM_PTT_SELECT): string => {
        if (data === "DTR") return "2";
        if (data === "RTS") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_07_CW_LCUT_FREQ]: (data: TYPE_CAT_EX_0701_CW_LCUT_FREQ): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_07_CW_LCUT_SLOPE]: (data: TYPE_CAT_EX_0702_CW_LCUT_SLOPE): string => data === "18dB/oct" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_07_CW_HCUT_FREQ]: (data: TYPE_CAT_EX_0703_CW_HCUT_FREQ): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_07_CW_HCUT_SLOPE]: (data: TYPE_CAT_EX_0704_CW_HCUT_SLOPE): string => data === "18dB/oct" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_07_CW_OUT_LEVEL]: (data: TYPE_CAT_EX_0705_CW_OUT_LEVEL): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_07_CW_AUTO_MODE]: (data: TYPE_CAT_EX_0706_CW_AUTO_MODE): string => {
        if (data === "ON") return "2";
        if (data === "50m") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_07_CW_BFO]: (data: TYPE_CAT_EX_0707_CW_BFO): string => {
        if (data === "AUTO") return "2";
        if (data === "LSB") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_07_CW_BKIN_TYPE]: (data: TYPE_CAT_EX_0708_CW_BKIN_TYPE): string => data === "FULL" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_07_CW_BKIN_DELAY]: (data: TYPE_CAT_EX_0709_CW_BKIN_DELAY): string => leftPad(data, 4, "0"),
    [CAT_COMMAND.CAT_EX_07_CW_WAVE_SHAPE]: (data: TYPE_CAT_EX_0710_CW_WAVE_SHAPE): string => data === "4msec" ? "2" : "1",
    [CAT_COMMAND.CAT_EX_07_CW_FREQ_DISPLAY]: (data: TYPE_CAT_EX_0711_CW_FREQ_DISPLAY): string => data === "PITCH" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_07_PC_KEYING]: (data: TYPE_CAT_EX_0712_CW_PC_KEYING): string => {
        if (data === "DTR") return "3";
        if (data === "RTS") return "2";
        if (data === "DAKY") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_07_QSK_DELAY_TIME]: (data: TYPE_CAT_EX_0713_QSK_DELAY_TIME): string => {
        if (data === "30ms") return "3";
        if (data === "25ms") return "2";
        if (data === "20ms") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_08_DATA_MODE]: (data: TYPE_CAT_EX_0801_DATA_MODE): string => data === "OTHERS" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_08_PSK_TONE]: (data: TYPE_CAT_EX_0802_PSK_TONE): string => {
        if (data === "2000Hz") return "2";
        if (data === "1500Hz") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_08_OTHER_DISP]: (data: TYPE_CAT_EX_0803_OTHER_DISP): string => `${data < 0 ? "-" : "+"}${Math.abs(data).toString().padStart(4, "0")}`,
    [CAT_COMMAND.CAT_EX_08_OTHER_SHIFT]: (data: TYPE_CAT_EX_0804_OTHER_SHIFT): string => `${data < 0 ? "-" : "+"}${Math.abs(data).toString().padStart(4, "0")}`,
    [CAT_COMMAND.CAT_EX_08_DATA_LCUT_FREQ]: (data: TYPE_CAT_EX_0805_DATA_LCUT_FREQ): string => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_08_DATA_LCUT_SLOPE]: (data: TYPE_CAT_EX_0806_DATA_LCUT_SLOPE): string => data === "18dB/oct" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_08_DATA_HCUT_FREQ]: (data: TYPE_CAT_EX_0807_DATA_HCUT_FREQ): string => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_08_DATA_HCUT_SLOPE]: (data: TYPE_CAT_EX_0808_DATA_HCUT_SLOPE): string => data === "18dB/oct" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_08_DATA_IN_SELECT]: (data: TYPE_CAT_EX_0809_DATA_IN_SELECT): string => data === "REAR" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_08_DATA_PTT_SELECT]: (data: TYPE_CAT_EX_0810_DATA_PTT_SELECT): string => {
        if (data === "DTR") return "2";
        if (data === "RTS") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_08_DATA_OUT_LEVEL]: (data: TYPE_CAT_EX_0811_DATA_OUT_LEVEL): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_08_DATA_BFO]: (data: TYPE_CAT_EX_0812_DATA_BFO): string => data === "LSB" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_09_FM_MIC_SELECT]: (data: TYPE_CAT_EX_0901_FM_MIC_SELECT): string => data === "REAR" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_09_FM_OUT_LEVEL]: (data: TYPE_CAT_EX_0902_FM_OUTPUT_LEVEL): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_09_PKT_PTT_SELECT]: (data: TYPE_CAT_EX_0903_PKT_PTT_SELECT): string => {
        if (data === "DTR") return "2";
        if (data === "RTS") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_09_RPT_SHIFT_28MHZ]: (data: TYPE_CAT_EX_0904_RPT_SHIFT_28MHZ): string => leftPad(data, 4, "0"),
    [CAT_COMMAND.CAT_EX_09_RPT_SHIFT_50MHZ]: (data: TYPE_CAT_EX_0905_RPT_SHIFT_50MHZ): string => leftPad(data, 4, "0"),
    [CAT_COMMAND.CAT_EX_09_DCS_POLARITY]: (data: TYPE_CAT_EX_0906_DCS_POLARITY): string => {
        if (data === "Tiv-Riv") return "3";
        if (data === "Tiv-Rn") return "2";
        if (data === "Tn-Riv") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_10_RTTY_LCUT_FREQ]: (data: TYPE_CAT_EX_1001_RTTY_LCUT_FREQ): string => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_10_RTTY_LCUT_SLOPE]: (data: TYPE_CAT_EX_1002_RTTY_LCUT_SLOPE): string => data === "18dB/oct" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_10_RTTY_HCUT_FREQ]: (data: TYPE_CAT_EX_1003_RTTY_HCUT_FREQ): string => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_10_RTTY_HCUT_SLOPE]: (data: TYPE_CAT_EX_1004_RTTY_HCUT_SLOPE): string => data === "18dB/oct" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_10_RTTY_SHIFT_PORT]: (data: TYPE_CAT_EX_1005_RTTY_SHIFT_PORT): string => {
        if (data === "RTS") return "2";
        if (data === "DTR") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_10_RTTY_POLARITY_R]: (data: TYPE_CAT_EX_1006_RTTY_POLARITY_R): string => data === "REV" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_10_RTTY_POLARITY_T]: (data: TYPE_CAT_EX_1007_RTTY_POLARITY_L): string => data === "REV" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_10_RTTY_OUT_LEVEL]: (data: TYPE_CAT_EX_1008_RTTY_OUT_LEVEL): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_10_RTTY_SHIFT_FREQ]: (data: TYPE_CAT_EX_1009_RTTY_SHIFT_FREQ): string => {
        if (data === "850Hz") return "3";
        if (data === "425Hz") return "2";
        if (data === "200Hz") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_10_RTTY_MARK_FREQ]: (data: TYPE_CAT_EX_1010_RTTY_MARK_FREQ): string => data === "2125Hz" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_10_RTTY_BFO]: (data: TYPE_CAT_EX_1011_RTTY_BFO): string => data === "LSB" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_11_SSB_LCUT_FREQ]: (data: TYPE_CAT_EX_1101_SSB_LCUT_FREQ): string => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_11_SSB_LCUT_SLOPE]: (data: TYPE_CAT_EX_1102_SSB_LCUT_SLOPE): string => data === "18dB/oct" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_11_SSB_HCUT_FREQ]: (data: TYPE_CAT_EX_1103_SSB_HCUT_FREQ): string => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_11_SSB_HCUT_SLOPE]: (data: TYPE_CAT_EX_1104_SSB_HCUT_SLOPE): string => data === "18dB/oct" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_11_SSB_MIC_SELECT]: (data: TYPE_CAT_EX_1105_SSB_MIC_SELECT): string => data === "REAR" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_11_SSB_OUT_LEVEL]: (data: TYPE_CAT_EX_1106_SSB_OUT_LEVEL): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_11_SSB_BFO]: (data: TYPE_CAT_EX_1107_SSB_BFO): string => {
        if (data === "AUTO") return "2";
        if (data === "LSB") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_11_SSB_PTT_SELECT]: (data: TYPE_CAT_EX_1108_SSB_PTT_SELECT): string => {
        if (data === "DTR") return "2";
        if (data === "RTS") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_11_SSB_TX_BPF]: (data: TYPE_CAT_EX_1109_SSB_TX_BPF): string => {
        if (data === "400-2600") return "4";
        if (data === "300-2700") return "3";
        if (data === "200-2800") return "2";
        if (data === "100-2900") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_12_APF_WIDTH]: (data: TYPE_CAT_EX_1201_APF_WIDTH): string => {
        if (data === "WIDE") return "2";
        if (data === "MEDIUM") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_12_CONTOUR_LEVEL]: (data: TYPE_CAT_EX_1202_CONTOUR_LEVEL): string => `${data < 0 ? "-" : "+"}${Math.abs(data).toString().padStart(2, "0")}`,
    [CAT_COMMAND.CAT_EX_12_CONTOUR_WIDTH]: (data: TYPE_CAT_EX_1203_CONTOUR_WIDTH): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_12_IF_NOTCH_WIDTH]: (data: TYPE_CAT_EX_1204_IF_NOTCH_WIDTH): string => data === "WIDE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_13_SCP_START_CYCLE]: (data: TYPE_CAT_EX_1301_SCP_START_CYCLE): string => {
        if (data === "10sec") return "3";
        if (data === "5sec") return "2";
        if (data === "3sec") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_13_SCP_SPAN_FREQ]: (data: TYPE_CAT_EX_1302_SCP_SPAN_FREQ): string => {
        if (data === "750kHz") return "4";
        if (data === "375kHz") return "3";
        if (data === "150kHz") return "2";
        if (data === "75kHz") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_14_QUICK_DIAL]: (data: TYPE_CAT_EX_1401_QUICK_DIAL): string => {
        if (data === "500kHz") return "2";
        if (data === "100kHz") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_14_SSB_DIAL_STEP]: (data: TYPE_CAT_EX_1402_SSB_DIAL_STEP): string => {
        if (data === "10Hz") return "2";
        if (data === "5Hz") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_14_AM_DIAL_STEP]: (data: TYPE_CAT_EX_1403_AM_DIAL_STEP): string => data === "100Hz" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_14_FM_DIAL_STEP]: (data: TYPE_CAT_EX_1404_FM_DIAL_STEP): string => data === "100Hz" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_14_DIAL_STEP]: (data: TYPE_CAT_EX_1405_DIAL_STEP): string => {
        if (data === "10Hz") return "2";
        if (data === "5Hz") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_14_AM_CH_STEP]: (data: TYPE_CAT_EX_1406_AM_CH_STEP): string => {
        if (data === "25kHz") return "5";
        if (data === "12.5kHz") return "4";
        if (data === "10kHz") return "3";
        if (data === "9kHz") return "2";
        if (data === "5kHz") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_14_FM_CH_STEP]: (data: TYPE_CAT_EX_1407_FM_CH_STEP): string => {
        if (data === "25kHz") return "6";
        if (data === "20kHz") return "5";
        if (data === "15kHz") return "4";
        if (data === "12.5kHz") return "3";
        if (data === "10kHz") return "2";
        if (data === "6.25kHz") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_15_EQ1_FREQ]: (data: TYPE_CAT_EX_1501_EQ1_FREQ) => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_15_EQ1_LEVEL]: (data: TYPE_CAT_EX_1502_EQ1_LEVEL): string => `${data < 0 ? "-" : "+"}${Math.abs(data).toString().padStart(2, "0")}`,
    [CAT_COMMAND.CAT_EX_15_EQ1_BWTH]: (data: TYPE_CAT_EX_1503_EQ1_BWTH): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_15_EQ2_FREQ]: (data: TYPE_CAT_EX_1504_EQ2_FREQ) => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_15_EQ2_LEVEL]: (data: TYPE_CAT_EX_1505_EQ2_LEVEL): string => `${data < 0 ? "-" : "+"}${Math.abs(data).toString().padStart(2, "0")}`,
    [CAT_COMMAND.CAT_EX_15_EQ2_BWTH]: (data: TYPE_CAT_EX_1506_EQ2_BWTH): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_15_EQ3_FREQ]: (data: TYPE_CAT_EX_1507_EQ3_FREQ) => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_15_EQ3_LEVEL]: (data: TYPE_CAT_EX_1508_EQ3_LEVEL): string => `${data < 0 ? "-" : "+"}${Math.abs(data).toString().padStart(2, "0")}`,
    [CAT_COMMAND.CAT_EX_15_EQ3_BWTH]: (data: TYPE_CAT_EX_1509_EQ3_BWTH): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_15_P_EQ1_FREQ]: (data: TYPE_CAT_EX_1510_PEQ1_FREQ) => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_15_P_EQ1_LEVEL]: (data: TYPE_CAT_EX_1511_PEQ1_LEVEL): string => `${data < 0 ? "-" : "+"}${Math.abs(data).toString().padStart(2, "0")}`,
    [CAT_COMMAND.CAT_EX_15_P_EQ1_BWTH]: (data: TYPE_CAT_EX_1512_PEQ1_BWTH): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_15_P_EQ2_FREQ]: (data: TYPE_CAT_EX_1513_PEQ2_FREQ) => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_15_P_EQ2_LEVEL]: (data: TYPE_CAT_EX_1514_PEQ2_LEVEL): string => `${data < 0 ? "-" : "+"}${Math.abs(data).toString().padStart(2, "0")}`,
    [CAT_COMMAND.CAT_EX_15_P_EQ2_BWTH]: (data: TYPE_CAT_EX_1515_PEQ2_BWTH): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_15_P_EQ3_FREQ]: (data: TYPE_CAT_EX_1516_PEQ3_FREQ) => data === "OFF" ? "00" : leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_15_P_EQ3_LEVEL]: (data: TYPE_CAT_EX_1517_PEQ3_LEVEL): string => `${data < 0 ? "-" : "+"}${Math.abs(data).toString().padStart(2, "0")}`,
    [CAT_COMMAND.CAT_EX_15_P_EQ3_BWTH]: (data: TYPE_CAT_EX_1518_PEQ3_BWTH): string => leftPad(data, 2, "0"),
    [CAT_COMMAND.CAT_EX_16_HF_SSB_PWR]: (data: TYPE_CAT_EX_1601_HF_SSB_PWR): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_HF_AM_PWR]: (data: TYPE_CAT_EX_1602_HF_AM_PWR): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_HF_PWR]: (data: TYPE_CAT_EX_1603_HF_PWR): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_50M_SSB_PWR]: (data: TYPE_CAT_EX_1604_50M_SSB_HF_PWR): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_50M_AM_PWR]: (data: TYPE_CAT_EX_1605_50M_AM_HF_PWR): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_50M_PWR]: (data: TYPE_CAT_EX_1606_50M_HF_PWR): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_SSB_MIC_GAIN]: (data: TYPE_CAT_EX_1607_SSB_MIC_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_AM_MIC_GAIN]: (data: TYPE_CAT_EX_1608_AM_MIC_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_FM_MIC_GAIN]: (data: TYPE_CAT_EX_1609_FM_MIC_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_DATA_MIC_GAIN]: (data: TYPE_CAT_EX_1610_DATA_MIC_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_SSB_DATA_GAIN]: (data: TYPE_CAT_EX_1611_SSB_DATA_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_AM_DATA_GAIN]: (data: TYPE_CAT_EX_1612_AM_DATA_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_FM_DATA_GAIN]: (data: TYPE_CAT_EX_1613_FM_DATA_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_DATA_DATA_GAIN]: (data: TYPE_CAT_EX_1614_DATA_DATA_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_TUNER_SELECT]: (data: TYPE_CAT_EX_1615_TUNER_SELECT): string => {
        if (data === "LAMP") return "3";
        if (data === "ATAS") return "2";
        if (data === "EXTERNAL") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_16_VOX_SELECT]: (data: TYPE_CAT_EX_1616_VOX_SELECT): string => data === "DATA" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_16_VOX_GAIN]: (data: TYPE_CAT_EX_1617_VOX_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_VOX_DELAY]: (data: TYPE_CAT_EX_1618_VOX_DELEAY): string => leftPad(data, 4, "0"),
    [CAT_COMMAND.CAT_EX_16_ANTI_DVOX_GAIN]: (data: TYPE_CAT_EX_1619_ANTI_VOX_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_DATA_VOX_GAIN]: (data: TYPE_CAT_EX_1620_DATA_VOX_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_DATA_VOX_DELAY]: (data: TYPE_CAT_EX_1621_DATA_VOX_DELAY): string => leftPad(data, 4, "0"),
    [CAT_COMMAND.CAT_EX_16_ANTI_DVOX_GAIN]: (data: TYPE_CAT_EX_1622_ANTI_DVOX_GAIN): string => leftPad(data, 3, "0"),
    [CAT_COMMAND.CAT_EX_16_EMERGENCY_FREQ]: (data: TYPE_CAT_EX_1623_EMERGENCY_FREQ): string => data === "ENABLE" ? "1" : "0",
    [CAT_COMMAND.CAT_EX_17_RESET]: (data: TYPE_CAT_EX_1701_RESET): string => {
        if (data === "FUNC") return "2";
        if (data === "DATA") return "1";
        return "0";
    },
    [CAT_COMMAND.CAT_EX_18_MAIN_VERSION]: (data: TYPE_CAT_EX_1801_MAIN_VERSION): string => leftPad(data, 4, "0"),
    [CAT_COMMAND.CAT_EX_18_DSP_VERSION]: (data: TYPE_CAT_EX_1802_DSP_VERSION): string => leftPad(data, 4, "0"),
    [CAT_COMMAND.CAT_EX_18_LCD_VERSION]: (data: TYPE_CAT_EX_1803_LCD_VERSION): string => leftPad(data, 4, "0"),
}

const parser = (message: string) => {
    if (message.charAt(message.length - 1) !== ';' || message.length < 3) {
        return;
    }

    const prefix = message.slice(0, 2);
    const command = prefix === "EX" ? message.slice(0, 4) : prefix;
    const data = prefix === "EX" ? message.slice(6, message.length - 1) : message.slice(2, message.length - 2);

    if (prefix === "EX") {
        const value = (FT891_DECODER as any)[command](data);
    }
}

export const ApiService = (apiProvide: ApiProvider) => {
    const encoder = new TextEncoder();

    const writeCAT = async <T>(command: CAT_COMMAND, data: T) => {
        const writer = apiProvide?.writer()?.getWriter();
        if (!writer) {
            await apiProvide.open();
            throw new Error("Port is not open!");
        }
        let value = (FT891_ENCODER as any)[command](data);

        await writer?.write(encoder.encode(`${command}${value};`));
        writer?.releaseLock();
    }

    return {
        open: apiProvide.open,
        writeCAT,
        // close: apiProvide.close,
        // writeString: apiProvide.writeString,
        addReadMessageCallback: apiProvide.addReadMessageCallback,
    }
};