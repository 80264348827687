import {WritableStream} from "stream/web";

export enum CAT_COMMAND {
    // Menu
    // 01 AGC
    CAT_EX_01_AGC_FAST_DELAY = "EX0101",
    CAT_EX_01_AGC_MID_DELAY = "EX0102",
    CAT_EX_01_AGC_SLOW_DELAY = "EX0103",

    // 02 DISPLAY
    CAT_EX_02_DISPLAY_CONTRAST = "EX0201",
    CAT_EX_02_DIMMER_BACKLIT = "EX0202",
    CAT_EX_02_DIMMER_LCD = "EX0203",
    CAT_EX_02_DIMMER_TX_BUSY = "EX0204",
    CAT_EX_02_PEAK_HOLD = "EX0205",
    CAT_EX_02_ZIN_LED = "EX0206",
    CAT_EX_02_POPUP_MENU = "EX0207",

    // 03 DVS
    CAT_EX_03_DVS_RX_OUT_LVL = "EX0301",
    CAT_EX_03_DVS_TX_OUT_LVL = "EX0302",

    // 04 KEYER
    CAT_EX_04_KEYER_TYPE = "EX0401",
    CAT_EX_04_KEYER_DOT_DASH = "EX0402",
    CAT_EX_04_CW_WEIGHT = "EX0403",
    CAT_EX_04_BEACON_INTERVAL = "EX0404",
    CAT_EX_04_NUMBER_STYLE = "EX0405",
    CAT_EX_04_CONTEST_NUMBER = "EX0406",
    CAT_EX_04_CW_MEMORY_1 = "EX0407",
    CAT_EX_04_CW_MEMORY_2 = "EX0408",
    CAT_EX_04_CW_MEMORY_3 = "EX0409",
    CAT_EX_04_CW_MEMORY_4 = "EX0410",
    CAT_EX_04_CW_MEMORY_5 = "EX0411",

    // 05 GENERAL
    CAT_EX_05_NB_WIDTH = "EX0501",
    CAT_EX_05_NB_REJECTION = "EX0502",
    CAT_EX_05_NB_LEVEL = "EX0503",
    CAT_EX_05_BEEP_LEVEL = "EX0504",
    CAT_EX_05_RF_SQL_VR = "EX0505",
    CAT_EX_05_CAT_RATE = "EX0506",
    CAT_EX_05_CAT_TOT = "EX0507",
    CAT_EX_05_CAT_RTS = "EX0508",
    CAT_EX_05_MEM_GROUP = "EX0509",
    CAT_EX_05_FM_SETTING = "EX0510",
    CAT_EX_05_REC_SETTING = "EX0511",
    CAT_EX_05_ATAS_SETTING = "EX0512",
    CAT_EX_05_QUICK_SPL_FREQ = "EX0513",
    CAT_EX_05_TX_TOT = "EX0514",
    CAT_EX_05_MIC_SCAN = "EX0515",
    CAT_EX_05_MIC_SCAN_RESUME = "EX0516",
    CAT_EX_05_REF_FREQ_ADJ = "EX0517",
    CAT_EX_05_CLEAR_SELECT = "EX0518",
    CAT_EX_05_APO = "EX0519",
    CAT_EX_05_FAN_CONTROL = "EX0520",

    // 06 MODE AM
    CAT_EX_06_AM_LCUT_FREQ = "EX0601",
    CAT_EX_06_AM_LCUT_SLOPE = "EX0602",
    CAT_EX_06_AM_HCUT_FREQ = "EX0603",
    CAT_EX_06_AM_HCUT_SLOPE = "EX0604",
    CAT_EX_06_AM_MIC_SELECT = "EX0605",
    CAT_EX_06_AM_OUT_LEVEL = "EX0606",
    CAT_EX_06_AM_PTT_SELECT = "EX0607",

    // 07 MODE CW
    CAT_EX_07_CW_LCUT_FREQ = "EX0701",
    CAT_EX_07_CW_LCUT_SLOPE = "EX0702",
    CAT_EX_07_CW_HCUT_FREQ = "EX0703",
    CAT_EX_07_CW_HCUT_SLOPE = "EX0704",
    CAT_EX_07_CW_OUT_LEVEL = "EX0705",
    CAT_EX_07_CW_AUTO_MODE = "EX0706",
    CAT_EX_07_CW_BFO = "EX0707",
    CAT_EX_07_CW_BKIN_TYPE = "EX0708",
    CAT_EX_07_CW_BKIN_DELAY = "EX0709",
    CAT_EX_07_CW_WAVE_SHAPE = "EX0710",
    CAT_EX_07_CW_FREQ_DISPLAY = "EX0711",
    CAT_EX_07_PC_KEYING = "EX0712",
    CAT_EX_07_QSK_DELAY_TIME = "EX0713",

    // 08 MODE DAT
    CAT_EX_08_DATA_MODE = "EX0801",
    CAT_EX_08_PSK_TONE = "EX0802",
    CAT_EX_08_OTHER_DISP = "EX0803",
    CAT_EX_08_OTHER_SHIFT = "EX0804",
    CAT_EX_08_DATA_LCUT_FREQ = "EX0805",
    CAT_EX_08_DATA_LCUT_SLOPE = "EX0806",
    CAT_EX_08_DATA_HCUT_FREQ = "EX0807",
    CAT_EX_08_DATA_HCUT_SLOPE = "EX0808",
    CAT_EX_08_DATA_IN_SELECT = "EX0809",
    CAT_EX_08_DATA_PTT_SELECT = "EX0810",
    CAT_EX_08_DATA_OUT_LEVEL = "EX0811",
    CAT_EX_08_DATA_BFO = "EX0812",

    // 09 MODE FM
    CAT_EX_09_FM_MIC_SELECT="0901",
    CAT_EX_09_FM_OUT_LEVEL="0902",
    CAT_EX_09_PKT_PTT_SELECT="0903",
    CAT_EX_09_RPT_SHIFT_28MHZ="0904",
    CAT_EX_09_RPT_SHIFT_50MHZ="0905",
    CAT_EX_09_DCS_POLARITY="0906",

    // 10 MODE RTTY
    CAT_EX_10_RTTY_LCUT_FREQ = "EX1001",
    CAT_EX_10_RTTY_LCUT_SLOPE = "EX1002",
    CAT_EX_10_RTTY_HCUT_FREQ = "EX1003",
    CAT_EX_10_RTTY_HCUT_SLOPE = "EX1004",
    CAT_EX_10_RTTY_SHIFT_PORT = "EX1005",
    CAT_EX_10_RTTY_POLARITY_R = "EX1006",
    CAT_EX_10_RTTY_POLARITY_T = "EX1007",
    CAT_EX_10_RTTY_OUT_LEVEL = "EX1008",
    CAT_EX_10_RTTY_SHIFT_FREQ = "EX1009",
    CAT_EX_10_RTTY_MARK_FREQ = "EX1010",
    CAT_EX_10_RTTY_BFO = "EX1011",

    // 11 MODE SSB
    CAT_EX_11_SSB_LCUT_FREQ = "EX1101",
    CAT_EX_11_SSB_LCUT_SLOPE = "EX1102",
    CAT_EX_11_SSB_HCUT_FREQ = "EX1103",
    CAT_EX_11_SSB_HCUT_SLOPE = "EX1104",
    CAT_EX_11_SSB_MIC_SELECT = "EX1105",
    CAT_EX_11_SSB_OUT_LEVEL = "EX1106",
    CAT_EX_11_SSB_BFO = "EX1107",
    CAT_EX_11_SSB_PTT_SELECT = "EX1108",
    CAT_EX_11_SSB_TX_BPF = "EX1109",

    // 12 RX DSP
    CAT_EX_12_APF_WIDTH = "EX1201",
    CAT_EX_12_CONTOUR_LEVEL = "EX1202",
    CAT_EX_12_CONTOUR_WIDTH = "EX1203",
    CAT_EX_12_IF_NOTCH_WIDTH = "EX1204",

    // 13 SCOPE
    CAT_EX_13_SCP_START_CYCLE = "EX1301",
    CAT_EX_13_SCP_SPAN_FREQ = "EX1302",

    // 14 TUNING
    CAT_EX_14_QUICK_DIAL = "EX1401",
    CAT_EX_14_SSB_DIAL_STEP = "EX1402",
    CAT_EX_14_AM_DIAL_STEP = "EX1403",
    CAT_EX_14_FM_DIAL_STEP = "EX1404",
    CAT_EX_14_DIAL_STEP = "EX1405",
    CAT_EX_14_AM_CH_STEP = "EX1406",
    CAT_EX_14_FM_CH_STEP = "EX1407",

    // 15 TX AUDIO
    CAT_EX_15_EQ1_FREQ = "EX1501",
    CAT_EX_15_EQ1_LEVEL = "EX1502",
    CAT_EX_15_EQ1_BWTH = "EX1503",
    CAT_EX_15_EQ2_FREQ = "EX1504",
    CAT_EX_15_EQ2_LEVEL = "EX1505",
    CAT_EX_15_EQ2_BWTH = "EX1506",
    CAT_EX_15_EQ3_FREQ = "EX1507",
    CAT_EX_15_EQ3_LEVEL = "EX1508",
    CAT_EX_15_EQ3_BWTH = "EX1509",
    CAT_EX_15_P_EQ1_FREQ = "EX1510",
    CAT_EX_15_P_EQ1_LEVEL = "EX1511",
    CAT_EX_15_P_EQ1_BWTH = "EX1512",
    CAT_EX_15_P_EQ2_FREQ = "EX1513",
    CAT_EX_15_P_EQ2_LEVEL = "EX1514",
    CAT_EX_15_P_EQ2_BWTH = "EX1515",
    CAT_EX_15_P_EQ3_FREQ = "EX1516",
    CAT_EX_15_P_EQ3_LEVEL = "EX1517",
    CAT_EX_15_P_EQ3_BWTH = "EX1518",

    // 16 TX GNRL
    CAT_EX_16_HF_SSB_PWR = "EX1601",
    CAT_EX_16_HF_AM_PWR = "EX1602",
    CAT_EX_16_HF_PWR = "EX1603",
    CAT_EX_16_50M_SSB_PWR = "EX1604",
    CAT_EX_16_50M_AM_PWR = "EX1605",
    CAT_EX_16_50M_PWR = "EX1606",
    CAT_EX_16_SSB_MIC_GAIN = "EX1607",
    CAT_EX_16_AM_MIC_GAIN = "EX1608",
    CAT_EX_16_FM_MIC_GAIN = "EX1609",
    CAT_EX_16_DATA_MIC_GAIN = "EX1610",
    CAT_EX_16_SSB_DATA_GAIN = "EX1611",
    CAT_EX_16_AM_DATA_GAIN = "EX1612",
    CAT_EX_16_FM_DATA_GAIN = "EX1613",
    CAT_EX_16_DATA_DATA_GAIN = "EX1614",
    CAT_EX_16_TUNER_SELECT = "EX1615",
    CAT_EX_16_VOX_SELECT = "EX1616",
    CAT_EX_16_VOX_GAIN = "EX1617",
    CAT_EX_16_VOX_DELAY = "EX1618",
    CAT_EX_16_ANTI_VOX_GAIN = "EX1619",
    CAT_EX_16_DATA_VOX_GAIN = "EX1620",
    CAT_EX_16_DATA_VOX_DELAY = "EX1621",
    CAT_EX_16_ANTI_DVOX_GAIN = "EX1622",
    CAT_EX_16_EMERGENCY_FREQ = "EX1623",

    // 17 RESET
    CAT_EX_17_RESET = "EX1701",

    // 18 VERSION
    CAT_EX_18_MAIN_VERSION = "EX1801",
    CAT_EX_18_DSP_VERSION = "EX1802",
    CAT_EX_18_LCD_VERSION = "EX1803",
}

export type TYPE_CAT_EX_0101_AGC_FAST_DELAY = number; // 20 - 4000
export type TYPE_CAT_EX_0102_AGC_MID_DELAY = number;  // 20 - 4000
export type TYPE_CAT_EX_0103_AGC_SLOW_DELAY = number; // 20 - 4000

export type TYPE_CAT_EX_0201_DISPLAY_CONTRAST = number; // 1 - 15
export type TYPE_CAT_EX_0202_DIMMER_BACKLIT = number; // 1 - 15
export type TYPE_CAT_EX_0203_DIMMER_LCD = number; // 1 - 15
export type TYPE_CAT_EX_0204_DIMMER_TX_BUSY = number; // 1 - 15
export type TYPE_CAT_EX_0205_PEAK_HOLD = "OFF" | "0.5s" | "1.0s" | "2.0s";
export type TYPE_CAT_EX_0206_ZIN_LED = "DISABLE" | "ENABLE";
export type TYPE_CAT_EX_0207_POPUP_MENU = "UPPER" | "LOWER";

export type TYPE_CAT_EX_0301_DVS_RX_OUT_LVL = number; // 0 - 100
export type TYPE_CAT_EX_0302_DVS_TX_OUT_LVL = number; // 0 - 100

export type TYPE_CAT_EX_0401_KEYER_TYPE = "OFF" | "BUG" | "ELEKEY-A" | "ELEKEY-B" | "ELEKEY-Y" | "ACS";
export type TYPE_CAT_EX_0402_KEYER_DOT_DASH = "NOR" | "REV";
export type TYPE_CAT_EX_0403_CW_WEIGHT = number; // 2.5 - 4.5
export type TYPE_CAT_EX_0404_BEACON_INTERVAL = number; // 0 - 690 (0 - off)
export type TYPE_CAT_EX_0405_NUMBER_STYLE = "1290" | "AUNO" | "AUNT" | "A2NO" | "A2NT" | "12NO" | "12NT";
export type TYPE_CAT_EX_0406_CONTEST_NUMBER = number; // 0 - 9999
export type TYPE_CAT_EX_0407_CW_MEMORY_1 = "TEXT" | "MESSAGE";
export type TYPE_CAT_EX_0408_CW_MEMORY_2 = "TEXT" | "MESSAGE";
export type TYPE_CAT_EX_0409_CW_MEMORY_3 = "TEXT" | "MESSAGE";
export type TYPE_CAT_EX_0410_CW_MEMORY_4 = "TEXT" | "MESSAGE";
export type TYPE_CAT_EX_0411_CW_MEMORY_5 = "TEXT" | "MESSAGE";

export type TYPE_CAT_EX_0501_NB_WIDTH = "1msec" | "3msec" | "10msec";
export type TYPE_CAT_EX_0502_NB_REJECTION = "10dB" | "30dB" | "50dB";
export type TYPE_CAT_EX_0503_NB_LEVEL = number;
export type TYPE_CAT_EX_0504_NB_BEEP_LEVEL = number;
export type TYPE_CAT_EX_0505_RF_SQL_VR = "RF" | "SQL";
export type TYPE_CAT_EX_0506_CAT_RATE = "4800bps" | "9600bps" | "19200bps" | "38400bps";
export type TYPE_CAT_EX_0507_CAT_TOT = "10msec" | "100msec" | "1000msec" | "3000msec";
export type TYPE_CAT_EX_0508_CAT_RTS = "DISABLE" | "ENABLE";
export type TYPE_CAT_EX_0509_MEM_GROUP = "DISABLE" | "ENABLE";
export type TYPE_CAT_EX_0510_FM_SETTINGS = "DISABLE" | "ENABLE";
export type TYPE_CAT_EX_0511_REC_SETTINGS = "DISABLE" | "ENABLE";
export type TYPE_CAT_EX_0512_ATAS_SETTINGS = "DISABLE" | "ENABLE";
export type TYPE_CAT_EX_0513_QUICK_SPL_FREQ = number;
export type TYPE_CAT_EX_0514_TX_TOT = number;
export type TYPE_CAT_EX_0515_MIC_SCAN = "DISABLE" | "ENABLE";
export type TYPE_CAT_EX_0516_MIC_SCAN_RESUME = "PAUSE" | "TIME";
export type TYPE_CAT_EX_0517_REF_FREQ_ADJ = number;
export type TYPE_CAT_EX_0518_CLEAR_SELECT = "RX" | "TX" | "TRX";
export type TYPE_CAT_EX_0519_APO = "OFF" | "1h" | "2h" | "4h" | "6h" | "8h" | "10h" | "12h";
export type TYPE_CAT_EX_0520_FAN_CONTROL = "NORMAL" | "CONTEST";

export type TYPE_CAT_EX_0601_AM_LCUT_FREQ = number;
export type TYPE_CAT_EX_0602_AM_LCUT_SLOPE = "6dB/oct" | "18dB/oct";
export type TYPE_CAT_EX_0603_AM_HCUT_FREQ = number;
export type TYPE_CAT_EX_0604_AM_HCUT_SLOPE = "6dB/oct" | "18dB/oct";
export type TYPE_CAT_EX_0605_AM_MIC_SELECT = "MIC" | "REAR";
export type TYPE_CAT_EX_0606_AM_OUT_LEVEL = number;
export type TYPE_CAT_EX_0607_AM_PTT_SELECT = "DAKY" | "RTS" | "DTR";

export type TYPE_CAT_EX_0701_CW_LCUT_FREQ = number;
export type TYPE_CAT_EX_0702_CW_LCUT_SLOPE = "6dB/oct" | "18dB/oct";
export type TYPE_CAT_EX_0703_CW_HCUT_FREQ = number;
export type TYPE_CAT_EX_0704_CW_HCUT_SLOPE = "6dB/oct" | "18dB/oct";
export type TYPE_CAT_EX_0705_CW_OUT_LEVEL = number;
export type TYPE_CAT_EX_0706_CW_AUTO_MODE = "OFF" | "50m" | "ON";
export type TYPE_CAT_EX_0707_CW_BFO = "USB" | "LSB" | "AUTO";
export type TYPE_CAT_EX_0708_CW_BKIN_TYPE = "SEMI" | "FULL";
export type TYPE_CAT_EX_0709_CW_BKIN_DELAY = number;
export type TYPE_CAT_EX_0710_CW_WAVE_SHAPE = "2msec" | "4msec";
export type TYPE_CAT_EX_0711_CW_FREQ_DISPLAY = "FREQ" | "PITCH";
export type TYPE_CAT_EX_0712_CW_PC_KEYING = "OFF" | "DAKY" | "RTS" | "DTR";
export type TYPE_CAT_EX_0713_QSK_DELAY_TIME = "15ms" | "20ms" | "25ms" | "30ms";

export type TYPE_CAT_EX_0801_DATA_MODE = "PSK" | "OTHERS";
export type TYPE_CAT_EX_0802_PSK_TONE = "1000Hz" | "1500Hz" | "2000Hz";
export type TYPE_CAT_EX_0803_OTHER_DISP = number;
export type TYPE_CAT_EX_0804_OTHER_SHIFT = number;
export type TYPE_CAT_EX_0805_DATA_LCUT_FREQ = "OFF" | number;
export type TYPE_CAT_EX_0806_DATA_LCUT_SLOPE = "6dB/oct" | "18dB/oct";
export type TYPE_CAT_EX_0807_DATA_HCUT_FREQ = "OFF" | number;
export type TYPE_CAT_EX_0808_DATA_HCUT_SLOPE = "6dB/oct" | "18dB/oct";
export type TYPE_CAT_EX_0809_DATA_IN_SELECT = "MIC" | "REAR";
export type TYPE_CAT_EX_0810_DATA_PTT_SELECT = "DAKY" | "RTS" | "DTR";
export type TYPE_CAT_EX_0811_DATA_OUT_LEVEL = number;
export type TYPE_CAT_EX_0812_DATA_BFO = "USB" | "LSB";

export type TYPE_CAT_EX_0901_FM_MIC_SELECT = "MIC" | "REAR";
export type TYPE_CAT_EX_0902_FM_OUTPUT_LEVEL = number;
export type TYPE_CAT_EX_0903_PKT_PTT_SELECT = "DAKY" | "RTS" | "DTR";
export type TYPE_CAT_EX_0904_RPT_SHIFT_28MHZ = number;
export type TYPE_CAT_EX_0905_RPT_SHIFT_50MHZ = number;
export type TYPE_CAT_EX_0906_DCS_POLARITY = "Tn-Rn" | "Tn-Riv" | "Tiv-Rn" | "Tiv-Riv";

export type TYPE_CAT_EX_1001_RTTY_LCUT_FREQ = "OFF" | number;
export type TYPE_CAT_EX_1002_RTTY_LCUT_SLOPE = "6dB/oct" | "18dB/oct";
export type TYPE_CAT_EX_1003_RTTY_HCUT_FREQ = "OFF" | number;
export type TYPE_CAT_EX_1004_RTTY_HCUT_SLOPE = "6dB/oct" | "18dB/oct";
export type TYPE_CAT_EX_1005_RTTY_SHIFT_PORT = "SHIFT" | "DTR" | "RTS";
export type TYPE_CAT_EX_1006_RTTY_POLARITY_R = "NOR" | "REV";
export type TYPE_CAT_EX_1007_RTTY_POLARITY_L = "NOR" | "REV";
export type TYPE_CAT_EX_1008_RTTY_OUT_LEVEL = number;
export type TYPE_CAT_EX_1009_RTTY_SHIFT_FREQ = "170Hz" | "200Hz" | "425Hz" | "850Hz";
export type TYPE_CAT_EX_1010_RTTY_MARK_FREQ = "1275Hz" | "2125Hz";
export type TYPE_CAT_EX_1011_RTTY_BFO = "USB" | "LSB";

export type TYPE_CAT_EX_1101_SSB_LCUT_FREQ = "OFF" | number;
export type TYPE_CAT_EX_1102_SSB_LCUT_SLOPE = "6dB/oct" | "18dB/oct";
export type TYPE_CAT_EX_1103_SSB_HCUT_FREQ = "OFF" | number;
export type TYPE_CAT_EX_1104_SSB_HCUT_SLOPE = "6dB/oct" | "18dB/oct";
export type TYPE_CAT_EX_1105_SSB_MIC_SELECT = "MIC" | "REAR";
export type TYPE_CAT_EX_1106_SSB_OUT_LEVEL = number;
export type TYPE_CAT_EX_1107_SSB_BFO = "USB" | "LSB" | "AUTO";
export type TYPE_CAT_EX_1108_SSB_PTT_SELECT = "DAKY" | "RTS" | "DTR";
export type TYPE_CAT_EX_1109_SSB_TX_BPF = "100-3000" | "100-2900" | "200-2800" | "300-2700" | "400-2600";

export type TYPE_CAT_EX_1201_APF_WIDTH = "NARROW" | "MEDIUM" | "WIDE";
export type TYPE_CAT_EX_1202_CONTOUR_LEVEL = number;
export type TYPE_CAT_EX_1203_CONTOUR_WIDTH = number;
export type TYPE_CAT_EX_1204_IF_NOTCH_WIDTH = "NARROW" | "WIDE";

export type TYPE_CAT_EX_1301_SCP_START_CYCLE = "OFF" | "3sec" | "5sec" | "10sec";
export type TYPE_CAT_EX_1302_SCP_SPAN_FREQ = "37.5kHz" | "75kHz" | "150kHz" | "375kHz" | "750kHz";

export type TYPE_CAT_EX_1401_QUICK_DIAL = "50kHz" | "100kHz" | "500kHz";
export type TYPE_CAT_EX_1402_SSB_DIAL_STEP = "2Hz" | "5Hz" | "10Hz";
export type TYPE_CAT_EX_1403_AM_DIAL_STEP = "10Hz" | "100Hz";
export type TYPE_CAT_EX_1404_FM_DIAL_STEP = "10Hz" | "100Hz";
export type TYPE_CAT_EX_1405_DIAL_STEP = "2Hz" | "5Hz" | "10Hz";
export type TYPE_CAT_EX_1406_AM_CH_STEP = "2.5kHz" | "5kHz" | "9kHz" | "10kHz" | "12.5kHz" | "25kHz";
export type TYPE_CAT_EX_1407_FM_CH_STEP = "5kHz" | "6.25kHz" | "10kHz" | "12.5kHz" | "15kHz" | "20kHz" | "25kHz";

export type TYPE_CAT_EX_1501_EQ1_FREQ = "OFF" | number;
export type TYPE_CAT_EX_1502_EQ1_LEVEL = number;
export type TYPE_CAT_EX_1503_EQ1_BWTH = number;
export type TYPE_CAT_EX_1504_EQ2_FREQ = "OFF" | number;
export type TYPE_CAT_EX_1505_EQ2_LEVEL = number;
export type TYPE_CAT_EX_1506_EQ2_BWTH = number;
export type TYPE_CAT_EX_1507_EQ3_FREQ = "OFF" | number;
export type TYPE_CAT_EX_1508_EQ3_LEVEL = number;
export type TYPE_CAT_EX_1509_EQ3_BWTH = number;
export type TYPE_CAT_EX_1510_PEQ1_FREQ = "OFF" | number;
export type TYPE_CAT_EX_1511_PEQ1_LEVEL = number;
export type TYPE_CAT_EX_1512_PEQ1_BWTH = number;
export type TYPE_CAT_EX_1513_PEQ2_FREQ = "OFF" | number;
export type TYPE_CAT_EX_1514_PEQ2_LEVEL = number;
export type TYPE_CAT_EX_1515_PEQ2_BWTH = number;
export type TYPE_CAT_EX_1516_PEQ3_FREQ = "OFF" | number;
export type TYPE_CAT_EX_1517_PEQ3_LEVEL = number;
export type TYPE_CAT_EX_1518_PEQ3_BWTH = number;

export type TYPE_CAT_EX_1601_HF_SSB_PWR = number;
export type TYPE_CAT_EX_1602_HF_AM_PWR = number;
export type TYPE_CAT_EX_1603_HF_PWR = number;
export type TYPE_CAT_EX_1604_50M_SSB_HF_PWR = number;
export type TYPE_CAT_EX_1605_50M_AM_HF_PWR = number;
export type TYPE_CAT_EX_1606_50M_HF_PWR = number;
export type TYPE_CAT_EX_1607_SSB_MIC_GAIN = number;
export type TYPE_CAT_EX_1608_AM_MIC_GAIN = number;
export type TYPE_CAT_EX_1609_FM_MIC_GAIN = number;
export type TYPE_CAT_EX_1610_DATA_MIC_GAIN = number;
export type TYPE_CAT_EX_1611_SSB_DATA_GAIN = number;
export type TYPE_CAT_EX_1612_AM_DATA_GAIN = number;
export type TYPE_CAT_EX_1613_FM_DATA_GAIN = number;
export type TYPE_CAT_EX_1614_DATA_DATA_GAIN = number;
export type TYPE_CAT_EX_1615_TUNER_SELECT = "OFF" | "EXTERNAL" | "ATAS" | "LAMP";
export type TYPE_CAT_EX_1616_VOX_SELECT = "MIC" | "DATA";
export type TYPE_CAT_EX_1617_VOX_GAIN = number;
export type TYPE_CAT_EX_1618_VOX_DELEAY = number;
export type TYPE_CAT_EX_1619_ANTI_VOX_GAIN = number;
export type TYPE_CAT_EX_1620_DATA_VOX_GAIN = number;
export type TYPE_CAT_EX_1621_DATA_VOX_DELAY = number;
export type TYPE_CAT_EX_1622_ANTI_DVOX_GAIN = number;
export type TYPE_CAT_EX_1623_EMERGENCY_FREQ = "DISABLE" | "ENABLE";

export type TYPE_CAT_EX_1701_RESET = "ALL" | "DATA" | "FUNC";

export type TYPE_CAT_EX_1801_MAIN_VERSION = number;
export type TYPE_CAT_EX_1802_DSP_VERSION = number;
export type TYPE_CAT_EX_1803_LCD_VERSION = number;








export type ReadMessageCallback = (message: string) => Promise<void>;

export interface ApiProvider {
    open: (url?: string) => Promise<void>;
    // close: () => Promise<void>;
    addReadMessageCallback: (callback: ReadMessageCallback) => void;
    writer: () => WritableStream;
}
