import React, {useEffect, useState} from "react";
import SliderComponent from "../../ui/slider/slider-component";
import {DvsSectionProps} from "./dvs-sections.types";
import {List} from "antd";

const DvsSection = ({dvsTxOutLevel, dvsRxOutLevel, onDvsRxOutLevelChange, onDvsTxOutLevelChange}: DvsSectionProps) => {
  const [txOutLevel, setTxOutLevel] = useState(dvsTxOutLevel);
  const [rxOutLevel, setRxOutLevel] = useState(dvsRxOutLevel);

  useEffect(() =>{
    setRxOutLevel(() => dvsRxOutLevel);
  }, [dvsRxOutLevel]);

  useEffect(() =>{
    setTxOutLevel(() => dvsTxOutLevel);
  }, [dvsTxOutLevel]);

  const onChangeDvsRxOutLevel = (value: number | null) => {
    setRxOutLevel(() => value ?? dvsRxOutLevel);
    if (onDvsRxOutLevelChange && rxOutLevel !== value) {
      onDvsRxOutLevelChange(value ?? dvsRxOutLevel);
    }
  }

  const onChangeDvsTxOutLevel = (value: number | null) => {
    setTxOutLevel(() => value ?? dvsTxOutLevel);
    if (onDvsTxOutLevelChange && txOutLevel !== value) {
      onDvsTxOutLevelChange(value ?? dvsTxOutLevel);
    }
  }

  return (<List
      size="small"
      bordered
    >
         <SliderComponent label='03-01&rarr;DVS RX OUT LVL' value={rxOutLevel} onChangeValue={(value) => onChangeDvsRxOutLevel(value)} maxValue={100} minValue={1} stepValue={1} />
         <SliderComponent label='03-02&rarr;DVS TX OUT LVL' value={txOutLevel} onChangeValue={(value) => onChangeDvsTxOutLevel(value)} maxValue={100} minValue={1} stepValue={1} />
  </List>);
};

export default DvsSection;