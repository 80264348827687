import {ApiProvider, CAT_COMMAND, ReadMessageCallback} from "../api-service.types";

class WSerialPort implements ApiProvider{
    constructor() {

        // this.listen();
    }

    public selectedPort: any = null;
    encoder = new TextEncoder();

    init() {

    }

    keepReading = true;
    reader: any;

    async readUntilClosed() {
        const decoder = new TextDecoder();
        while (this?.selectedPort?.readable && this.keepReading) {
            this.reader = this.selectedPort.readable.getReader();
            try {
                while (true) {
                    const { value, done } = await this.reader.read();
                    if (done) {
                        // |reader| has been canceled.
                        break;
                    }
                    const catMessage = decoder.decode(value);
                    console.log("READ DATA BINARY: ", value);
                    console.log("READ DATA ASCII: ", catMessage);
                    // Do something with |value|...
                }
            } catch (error) {
                // Handle |error|...
            } finally {
                this.reader.releaseLock();
            }
        }

        await this.selectedPort.close();
    }

    connect() {
        (navigator as any)?.serial
            // .requestPort({ filters: [{ usbVendorId }] })
            // .requestPort({ filters: [] })
            .requestPort()
            .then(async (port: any) => {


                this.selectedPort = port;

                await this.selectedPort.open({ baudRate: 38400 });

                console.log("Serial i ready and connected!", port.toString());
                console.log("Info: ", port.getInfo());
                this.readUntilClosed();

                setInterval(() =>{
                    (async() => {
                        const encoder = new TextEncoder();
                        const writer = webSerialPort.selectedPort?.writable.getWriter();
                        await writer?.write(encoder.encode(`EX0101;`));
                        writer?.releaseLock();
                    })().catch((error) => {
                        console.log("Error while writing to serial port:", error);
                    })
                }, 1000);

                this.selectedPort?.addEventListener("connect", (e: any) => {
                    // Connect to `e.target` or add it to a list of available ports.
                    console.log("Connected", e);
                });

                this.selectedPort?.addEventListener("disconnect", (e: any) => {
                    // Remove `e.target` from the list of available ports.
                    console.log("Diconnected ", e);
                });

                (navigator as any)?.serial.getPorts().then((ports: any[]) => {
                    // Initialize the list of available ports with `ports` on page load.
                    console.log("List port: ", ports);
                });


                // Connect to `port` or add it to the list of available ports.
            })
            .catch((e: any) => {
                console.log("Error while opening the serial port", e);
                // The user didn't select a port.
            });
    }

    async writeString(message: string) {
        try {
            const writer = webSerialPort.selectedPort?.writable.getWriter();
            await writer?.write(this.encoder.encode(message));
            writer?.releaseLock();
        } catch(e) {
            alert("Serialport disconnect!");
        }
    }

    async writeCommand(command: CAT_COMMAND) {

    }


    async open() {

    }

    writer(): WritableStream {
        return webSerialPort.selectedPort?.writable;
    }

    addReadMessageCallback(callback: ReadMessageCallback) {

    }
}

const webSerialPort = new WSerialPort();

export{ webSerialPort };