import React, {ReactNode, useEffect, useState} from "react";
import {Collapse, List, Switch} from "antd";

interface LabelComponentProps {
    label: string;
    unit?: string;
    marginTop?: string | number;
    marginLeft?: string | number;
}

const   LabelComponent: React.FC<LabelComponentProps> = ({ label, unit, marginTop = -25, marginLeft = "-10px" }) => (
    <p style={{ position: 'absolute', marginTop, marginLeft, fontSize: '9px', color: '#757070' }}>{label} <span hidden={!unit || unit.length === 0}>({unit})</span></p>
    );

export default LabelComponent;